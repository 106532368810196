import React, { useContext, useMemo } from 'react';

import { Grid } from '@material-ui/core';

import TicketsList from '../../components/TicketsList';

import { AuthContext } from '../../context/Auth/AuthContext';

export default function TicketsPendingPage() {
  const { userQueues } = useContext(AuthContext);
  const whatsAppsQueuesIds = useMemo(() => userQueues.map(q => q.id), [userQueues]);
  return (
    <Grid container>
      <Grid item md={6}>
        <TicketsList status="pending" selectedQueueIds={whatsAppsQueuesIds} columns={2} readOnly />
      </Grid>
      <Grid item md={6}>
        <TicketsList status="open" selectedQueueIds={whatsAppsQueuesIds} columns={2} showAll readOnly />
      </Grid>
    </Grid>
  );
}
