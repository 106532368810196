import React, { ChangeEvent, ReactElement, useCallback, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useField, useFormikContext } from 'formik';
import { isQueue, Queue } from '../../services/types';
import { i18n } from '../../translate/i18n';
import { useStyles } from './styles';
import { Props } from './types';

export default function QueueField({ name, label, queues, autoFocus = false }: Props): ReactElement {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<Queue>({ name });

  const value = isQueue(field.value) ? `${field.value.id}` : '';

  useEffect(() => {
    if (!value && queues.length > 0) {
      if (isQueue(queues[0])) {
        setFieldValue(name, queues[0]);
      }
    }
  }, [name, queues, setFieldValue, value]);

  const changeHandler = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      const { value: queueId } = e.target;
      if (typeof queueId === 'number') {
        const queue = queues.find(q => q.id === queueId);
        if (isQueue(queue)) {
          setFieldValue(name, queue);
        }
      }
    },
    [name, queues, setFieldValue]
  );

  const valueRender = useCallback(
    (queueId: unknown) => {
      if (typeof queueId === 'string') {
        const queue = queues.find(q => `${q.id}` === queueId);
        if (isQueue(queue)) {
          return (
            <div className={classes.chips}>
              <Chip
                style={{ backgroundColor: queue.color }}
                variant="outlined"
                label={queue.name}
                className={classes.chip}
              />
            </div>
          );
        }
      }
      return <></>;
    },
    [queues, classes]
  );

  return (
    <FormControl variant="outlined" error={meta.touched && !!meta.error} fullWidth>
      <InputLabel id={`${name}-label`}>{i18n.t(label)}</InputLabel>
      <Select
        label={i18n.t(label)}
        labelId={`${name}-label`}
        id={name}
        name={name}
        value={value}
        onChange={changeHandler}
        renderValue={valueRender}
        autoFocus={autoFocus}
      >
        {queues.filter(isQueue).map(queue => (
          <MenuItem key={queue.id} value={queue.id}>
            {queue.name}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && !!meta.error ? <FormHelperText>{meta.error}</FormHelperText> : null}
    </FormControl>
  );
}
