import React, { ReactElement, createContext, useContext, ReactNode } from 'react';
import { useMediaQuery } from '@material-ui/core';

type IsMobileContextData = {
  isMobile: boolean;
};

type IsMobileProviderProps = {
  children: ReactNode;
};

export const IsMobileContext = createContext({} as IsMobileContextData);

function IsMobileProvider({ children }: IsMobileProviderProps): ReactElement {
  const isMobile = useMediaQuery('(max-width:960px)');
  return (
    <IsMobileContext.Provider
      value={{
        isMobile,
      }}
    >
      {children}
    </IsMobileContext.Provider>
  );
}

function useIsMobile(): IsMobileContextData {
  const context = useContext(IsMobileContext);

  return context;
}

export { IsMobileProvider, useIsMobile };
