import React, { ReactElement, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Can } from '../../components/Can';
import ConfirmationModal from '../../components/ConfirmationModal';
import ContactModal from '../../components/ContactModal';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import TicketPrepareModal from '../../components/TicketPrepareModal';
import Title from '../../components/Title';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api, { createSocketIo } from '../../services/api';
import { Contact, isContact, isQueue, Queue } from '../../services/types';
import { i18n } from '../../translate/i18n';
import useStyles from './styles';
import { ActionProps } from './types';
import useIsMounted from '../../hooks/useIsMounted';

/* eslint-disable no-param-reassign */
function reducer(state: Contact[], action: ActionProps): Contact[] {
  if (action.type === 'LOAD_CONTACTS') {
    const contacts = action.payload as Contact[];
    const newContacts: Contact[] = [];
    contacts.forEach((contact: Contact) => {
      const contactIndex = state.findIndex(c => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });
    return [...state, ...newContacts];
  }
  if (action.type === 'UPDATE_CONTACTS') {
    const contact = action.payload as Contact;
    const contactIndex = state.findIndex(c => c.id === contact.id);
    if (contactIndex !== -1) {
      state[contactIndex] = contact;
    }
    return [...state];
  }
  if (action.type === 'DELETE_CONTACT') {
    const contactId = action.payload;
    const contactIndex = state.findIndex(c => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }
  if (action.type === 'RESET') {
    return [];
  }
  return [];
}

export default function Contacts(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useIsMounted();
  const selectedContact = useRef<Contact>();

  const { user } = useContext(AuthContext);

  const [contacts, dispatch] = useReducer(reducer, []);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState('');
  const [selectedContactId, setSelectedContactId] = useState<number>();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [ticketPrepareModalOpen, setTicketPrepareModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState<Contact>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        try {
          const { data } = await api.get('/contacts/', {
            params: { searchParam, pageNumber, isGroups: false },
          });
          dispatch({ type: 'LOAD_CONTACTS', payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      })();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = createSocketIo();
    socket.on('contact', (data: any) => {
      if (data.action === 'update') {
        dispatch({ type: 'UPDATE_CONTACTS', payload: data.contact });
      }
      if (data.action === 'delete') {
        dispatch({ type: 'DELETE_CONTACT', payload: +data.contactId });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = useCallback(event => {
    setSearchParam(event.target.value.toLowerCase());
  }, []);

  const handleOpenContactModal = useCallback(() => {
    setSelectedContactId(undefined);
    setContactModalOpen(true);
  }, []);

  const handleCloseContactModal = useCallback(() => {
    setSelectedContactId(undefined);
    setContactModalOpen(false);
  }, []);

  const handleCloseTicketPrepareModal = useCallback(() => {
    if (isMounted.current) {
      selectedContact.current = undefined;
      setTicketPrepareModalOpen(false);
    }
  }, [isMounted, selectedContact]);

  const handleSaveTicket = useCallback(
    (contact?: Contact, queueId?: number): Promise<unknown> | undefined => {
      if (!isContact(contact)) {
        return undefined;
      }
      selectedContact.current = contact;
      return api
        .post('/tickets', {
          userId: user?.id,
          status: 'open',
          contactId: contact.id,
          queueId,
        })
        .then(({ data: ticket }) => {
          history.replace(`/tickets/${ticket.id}`);
        })
        .catch(err => {
          if (err.response?.data?.error === 'ERR_MANY_QUEUES_OR_CONNECTIONS') {
            setTicketPrepareModalOpen(true);
          } else {
            toastError(err);
          }
        });
    },
    [history, user]
  );

  const handleSelectTicketPrepareModal = useCallback(
    (values: { queue: string | Queue }): Promise<unknown> | undefined => {
      if (isContact(selectedContact.current) && isQueue(values.queue)) {
        return handleSaveTicket(selectedContact.current, values.queue.id);
      }
      return undefined;
    },
    [handleSaveTicket, selectedContact]
  );

  const handleEditContact = useCallback((contact?: Contact) => {
    if (isContact(contact)) {
      setSelectedContactId(contact.id);
      setContactModalOpen(true);
    }
  }, []);

  const handleDeleteContact = useCallback(async contactId => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t('contacts.toasts.deleted'));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(undefined);
    setSearchParam('');
    setPageNumber(1);
  }, []);

  const handleimportContact = useCallback(async () => {
    try {
      await api.post('/contacts/import');
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  }, [history]);

  const loadMore = useCallback(() => {
    setPageNumber(prevState => prevState + 1);
  }, []);

  const handleScroll = useCallback(
    e => {
      if (!hasMore || loading) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        loadMore();
      }
    },
    [hasMore, loadMore, loading]
  );

  return (
    <MainContainer>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <TicketPrepareModal
        open={ticketPrepareModalOpen}
        onClose={handleCloseTicketPrepareModal}
        onSelect={handleSelectTicketPrepareModal}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t('contacts.confirmationModal.deleteTitle')} ${deletingContact.name}?`
            : `${i18n.t('contacts.confirmationModal.importTitlte')}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() => (deletingContact ? handleDeleteContact(deletingContact.id) : handleimportContact())}
      >
        {deletingContact
          ? `${i18n.t('contacts.confirmationModal.deleteMessage')}`
          : `${i18n.t('contacts.confirmationModal.importMessage')}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t('contacts.title')}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t('contacts.searchPlaceholder')}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: 'gray' }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
            {i18n.t('contacts.buttons.import')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpenContactModal}>
            {i18n.t('contacts.buttons.add')}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t('contacts.table.name')}</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.whatsapp')}</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.email')}</TableCell>
              <TableCell align="center">{i18n.t('contacts.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map(contact => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Avatar src={contact.profilePicUrl} />
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleSaveTicket(contact)}>
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleEditContact(contact)}>
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}
