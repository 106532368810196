import dark from './dark';
import light from './light';

const themes = {
  dark,
  light,
};

export default function getTheme(theme: string): Record<any, any> {
  if (theme === 'dark') {
    return themes.dark;
  }
  if (theme === 'light') {
    return themes.light;
  }
  return themes.light;
}
