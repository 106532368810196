import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ModalImage from '../ModalImage';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  messageMedia: {
    objectFit: 'cover',
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const ModalImageCors = ({ imageUrl }) => {
  const classes = useStyles();
  const [fetching, setFetching] = useState(true);
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    if (!imageUrl) return;
    api
      .get(imageUrl, {
        responseType: 'blob',
      })
      .then(({ data, headers }) => window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] })))
      .then(url => setBlobUrl(url))
      .catch(err => console.warn('Imagem indisponível!', err))
      .finally(() => setFetching(false));
  }, [imageUrl]);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={fetching ? imageUrl : blobUrl}
      medium={fetching ? imageUrl : blobUrl}
      large={fetching ? imageUrl : blobUrl}
      alt="image"
    />
  );
};

export default ModalImageCors;
