import React, { ReactElement, useState, useCallback, useEffect, ChangeEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContactModal from '../ContactModal';
import { FormContactProps } from '../ContactModal/types';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';

import { Props } from './types';
import toastError from '../../errors/toastError';
import { Contact, isContact } from '../../services/types';

const filter = createFilterOptions<any>({
  trim: true,
});

export default function ContactField(props: Props): ReactElement {
  const { name, required = false, autoFocus = false } = props;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<Contact>(props);
  const [options, setOptions] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [newContact, setNewContact] = useState<FormContactProps>({ name: '' } as FormContactProps);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    if (searchParam.length >= 3) {
      setLoading(true);
      delayDebounceFn = setTimeout(() => {
        api
          .get('/contacts', {
            params: { searchParam, isGroups: false },
          })
          .then(({ data }) => setOptions(data.contacts))
          .catch(err => toastError(err))
          .finally(() => setLoading(false));
      }, 500);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  const renderOption = useCallback((option: Contact) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    }
    return `${i18n.t('newTicketModal.add')} ${option.name}`;
  }, []);

  const createAddContactOption = useCallback(
    (filterOptions, params) => {
      const filtered = filter(filterOptions, params);
      if (params.inputValue !== '' && !loading && searchParam.length >= 3) {
        filtered.push({
          name: `${params.inputValue}`,
          number: '',
        });
      }
      return filtered;
    },
    [loading, searchParam.length]
  );

  const handleRenderOptionLabel = useCallback((option: string | Contact | null) => {
    if (isContact(option)) {
      return `${option.name} - ${option.number}`;
    }
    return '';
  }, []);

  const handleGroupBy = useCallback((option: string | Contact | null) => {
    if (isContact(option)) {
      return option.whatsapp.name;
    }
    return '';
  }, []);

  const handleSelectContact = useCallback(
    (e: ChangeEvent<unknown>, newValue: any) => {
      if (isContact(newValue)) {
        if (newValue.number) {
          setFieldValue(name, newValue);
        }
      } else if (newValue && newValue?.name) {
        setNewContact({ name: newValue.name });
        setContactModalOpen(true);
      }
    },
    [name, setFieldValue]
  );

  const handleCloseContactModal = useCallback(() => {
    setContactModalOpen(false);
  }, []);

  const handleAddNewContactTicket = useCallback(
    contact => {
      setFieldValue(name, contact);
    },
    [name, setFieldValue]
  );

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
      />
      <Autocomplete
        value={field.value}
        getOptionLabel={handleRenderOptionLabel}
        options={options}
        loading={loading}
        renderOption={renderOption}
        filterOptions={createAddContactOption}
        groupBy={handleGroupBy}
        fullWidth
        clearOnBlur
        autoHighlight
        freeSolo
        clearOnEscape
        onChange={(e, newValue) => {
          return handleSelectContact(e, newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={i18n.t('newTicketModal.fieldLabel')}
            variant="outlined"
            required={required}
            autoFocus={autoFocus}
            onChange={e => setSearchParam(e.target.value)}
            helperText={meta.touched && meta.error}
            error={meta.touched && !!meta.error}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
