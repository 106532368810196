import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { Badge } from '@material-ui/core';
import ListItemLink from '../ListItemLink';
import { i18n } from '../../translate/i18n';
import api, { createSocketIo } from '../../services/api';
import { Props } from './types';

export default function ListItemTicketsLink({ userQueueIds, onNavigationMenuDrawer }: Props): ReactElement {
  const [numberTickets, setNumberTickets] = useState(0);

  const getTickets = useCallback(() => {
    api
      .get('/tickets', {
        params: {
          status: 'open',
          isGroups: false,
          showAll: false,
          queueIds: JSON.stringify(userQueueIds),
        },
      })
      .then(({ data }) => {
        setNumberTickets(data.tickets ? data.tickets.length : 0);
      })
      .catch(err => {
        console.error(err);
      });
  }, [userQueueIds]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  useEffect(() => {
    const socket = createSocketIo();

    socket.on('connect', () => socket.emit('joinTickets', 'pending'));
    socket.on('ticket', (data: any) => {
      if (data.action === 'updateUnread' || data.action === 'update' || data.action === 'delete') {
        getTickets();
      }
    });

    socket.on('appMessage', (data: any) => {
      if (data.action === 'create') {
        getTickets();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [getTickets]);

  return (
    <ListItemLink
      to={() => onNavigationMenuDrawer('/tickets')}
      primary={i18n.t('mainDrawer.listItems.tickets')}
      icon={
        <Badge badgeContent={numberTickets} color="secondary">
          <ChromeReaderModeIcon color="primary" />
        </Badge>
      }
    />
  );
}
