import Button from '@material-ui/core/Button';
import React, { ReactElement } from 'react';
import { i18n } from '../../translate/i18n';
import { ButtonCloseProps } from './types';

export default function ButtonClose({ onClick, loading }: ButtonCloseProps): ReactElement {
  return (
    <Button onClick={onClick} color="secondary" disabled={loading} variant="outlined">
      {i18n.t('newsModal.buttons.close')}
    </Button>
  );
}
