import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Ticket from '../../../../components/Ticket';

import { i18n } from '../../../../translate/i18n';

import { Params } from '../../types';
import useStyles from '../../styles';

export default function Chat(): ReactElement {
  const classes = useStyles();
  const { ticketId } = useParams() as Params;
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.messagessWrapper}>
        {ticketId ? (
          <Ticket />
        ) : (
          <Paper square variant="outlined" className={classes.welcomeMsg}>
            <span>{i18n.t('chat.noTicketMessage')}</span>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
