import React, { MouseEvent, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import QueueIcon from '@material-ui/icons/Queue';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import BackdropLoading from '../components/BackdropLoading';
import NewsModal from '../components/NewsModal';
import NewTicketModal from '../components/NewTicketModal';
import NotificationsPopOver from '../components/NotificationsPopOver';
import UserModal from '../components/UserModal';
import { AuthContext } from '../context/Auth/AuthContext';
import { useManagerMainMenu } from '../context/ManagerMainMenuDrawer';
import { useIsMobile } from '../hooks/useIsMobile';
import { useTheme } from '../hooks/useTheme';
import { i18n } from '../translate/i18n';
import MainMenuDrawer from './MainMenuDrawer';
import MenuQueueDrawer from './MenuQueueDrawer';
import useStyles from './styles';
import { OwnProps } from './types';

export default function LoggedInLayout({ children }: OwnProps): ReactElement {
  const { isMobile } = useIsMobile();
  const history = useHistory();
  const { mainMenuOpen, handleToggleMainMenu, handleCloseMainMenu } = useManagerMainMenu();
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, handleLogout, loading } = useContext(AuthContext);
  const { toggleTheme, theme } = useTheme();

  const handleMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setMenuOpen(false);
  }, []);

  const handleOpenUserModal = useCallback(() => {
    setUserModalOpen(true);
    handleCloseMenu();
  }, [handleCloseMenu]);

  const handleOpenContacts = useCallback(() => {
    handleCloseMenu();
    history.replace(`/users/${user.id}/contacts`);
  }, [history, handleCloseMenu, user.id]);

  const handleClickLogout = useCallback(() => {
    handleCloseMenu();
    handleLogout();
  }, [handleCloseMenu, handleLogout]);

  useEffect(() => {
    if (isMobile) {
      handleCloseMainMenu();
    }
  }, [isMobile, handleCloseMainMenu]);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      {user.id && <MainMenuDrawer />}
      <NewTicketModal modalOpen={newTicketModalOpen} onClose={() => setNewTicketModalOpen(false)} />
      <UserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} userId={user?.id} />
      <AppBar
        position="absolute"
        variant="outlined"
        className={clsx(classes.appBar, mainMenuOpen && classes.appBarShift)}
        color="inherit"
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            onClick={handleToggleMainMenu}
            color="primary"
            className={clsx(!mainMenuOpen && classes.menuButtonHidden)}
          >
            <ChevronLeftIcon />
          </IconButton>

          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleToggleMainMenu}
            className={clsx(classes.menuButton, mainMenuOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          {!mainMenuOpen ? (
            <Typography component="h1" variant="h6" color="textPrimary" noWrap className={classes.title}>
              Atendimento Top
            </Typography>
          ) : (
            <Typography component="h1" variant="h6" color="textPrimary" noWrap className={classes.title} />
          )}

          {user.id && (
            <IconButton
              aria-label="open new ticket user"
              aria-controls="opentikecet-appbar"
              aria-haspopup="true"
              onClick={() => setNewTicketModalOpen(true)}
              color="primary"
            >
              <QueueIcon />
            </IconButton>
          )}
          {user.id && <MenuQueueDrawer />}
          {user.id && <NotificationsPopOver />}
          <IconButton onClick={toggleTheme} color="primary" style={{ marginLeft: '10px' }}>
            {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
              style={{ marginLeft: '10px' }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              color="primary"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
              style={{ marginLeft: '10px' }}
            >
              <MenuItem onClick={handleOpenUserModal}>{i18n.t('mainDrawer.appBar.user.profile')}</MenuItem>
              <MenuItem onClick={handleOpenContacts}>{i18n.t('mainDrawer.appBar.user.contacts')}</MenuItem>
              <MenuItem onClick={handleClickLogout}>{i18n.t('mainDrawer.appBar.user.logout')}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children || null}
      </main>
      {user.id && <NewsModal />}
    </div>
  );
}
