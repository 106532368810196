import React, { ReactElement, useState, useCallback, useMemo, useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';

import TicketsList from '../TicketsList';

import { AuthContext } from '../../context/Auth/AuthContext';

import { i18n } from '../../translate/i18n';
import { Queue } from '../../services/types';
import useStyles from './styles';

export default function TicketsGroupManager(): ReactElement {
  const classes = useStyles();
  const [searchParam, setSearchParam] = useState('');

  const { user } = useContext(AuthContext);

  const selectedQueueIds = useMemo(() => {
    return user ? user.queues.map((q: Queue) => q.id) : [];
  }, [user]);

  const handleSearch = useCallback(e => {
    const searchedTerm = e.target.value.toLowerCase();
    setSearchParam(searchedTerm);
  }, []);

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <Typography variant="h6" color="inherit" style={{ paddingTop: 5 }}>
          {i18n.t('tickets.tabs.group.title')}
        </Typography>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.serachInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            placeholder={i18n.t('tickets.search.placeholder')}
            type="search"
            onChange={handleSearch}
          />
        </div>
      </Paper>
      <TicketsList
        status="open"
        selectedQueueIds={selectedQueueIds}
        searchParam={searchParam}
        isGroups
        showAll={false}
      />
    </Paper>
  );
}
