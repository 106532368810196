import React, { ReactElement, useEffect, useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Field, FieldArray, Form, Formik } from 'formik';

import toastError from '../../errors/toastError';
import useUserWhatsApps from '../../hooks/useUserWhatsApps';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import WhatsappField from '../WhatsappField';

import WarningWhatsNumber from './WarningWhatsNumber';
import WarningModal from '../WarningModal';

import { FormContactProps, ContactModalProps } from './types';
import useStyles from './styles';

const ContactSchema = Yup.object().shape({
  whatsappId: Yup.string().required('Obrigatório'),
  name: Yup.string().min(2, 'Muito Curto!').max(200, 'Muito Longo!').required('Obrigatório'),
  number: Yup.string()
    // .matches(/^55..*$/, 'O número deve iniciar com 55!')
    .min(12, 'Muito Curto!')
    .max(13, 'Muito Longo!'),
  email: Yup.string().email('Email inválido'),
});

const initialState: FormContactProps = {
  whatsappIdEnabled: true,
  whatsappId: '',
  name: '',
  number: '',
  email: '',
  extraInfo: [],
};

export default function ContactModal({
  open,
  onClose,
  contactId,
  initialValues,
  onSave,
}: ContactModalProps): ReactElement {
  const classes = useStyles();
  const isMounted = useRef(true);
  const whatsapps = useUserWhatsApps();
  const [contact, setContact] = useState(initialState);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormContactProps>(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async (): Promise<void> => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues, whatsappIdEnabled: true };
        });
      }
      if (!contactId) {
        return;
      }
      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact({ ...data, whatsappId: data.whatsapp.id, whatsappIdEnabled: false });
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = useCallback(() => {
    onClose();
    setContact(initialState);
  }, [onClose]);

  const handleSaveContact = useCallback(
    async (values: FormContactProps) => {
      try {
        if (contactId) {
          await api.put(`/contacts/${contactId}`, values);
          handleClose();
        } else {
          const { data } = await api.post('/contacts', values);
          if (onSave) {
            onSave(data);
          }
          handleClose();
        }
        toast.success(i18n.t('contactModal.success'));
      } catch (err) {
        toastError(err);
      }
    },
    [contactId, handleClose, onSave]
  );

  const handleCancelWarningModal = useCallback(() => {
    setWarningModalOpen(false);
  }, []);

  const handleConfirmWarningModal = useCallback(() => {
    handleSaveContact(formData);
    setWarningModalOpen(false);
    handleClose();
  }, [formData, handleSaveContact, handleClose]);

  return (
    <div className={classes.root}>
      <WarningModal
        open={warningModalOpen}
        content={<WarningWhatsNumber />}
        onConfirm={handleConfirmWarningModal}
        onClose={handleCancelWarningModal}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId ? `${i18n.t('contactModal.title.edit')}` : `${i18n.t('contactModal.title.add')}`}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              setWarningModalOpen(true);
              setFormData(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <WhatsappField
                  name="whatsappId"
                  label="contactModal.form.whatsappId"
                  whatsapps={whatsapps}
                  enabled={contact.whatsappIdEnabled}
                />
                <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1" gutterBottom>
                  {i18n.t('contactModal.form.mainInfo')}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t('contactModal.form.name')}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t('contactModal.form.number')}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="5513912344321"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
                <WarningWhatsNumber />
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('contactModal.form.email')}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="Email address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
                  {i18n.t('contactModal.form.extraInfo')}
                </Typography>

                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div key={`${info.name}-info`} className={classes.extraAttr}>
                            <Field
                              as={TextField}
                              label={i18n.t('contactModal.form.extraName')}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t('contactModal.form.extraValue')}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton size="small" onClick={() => remove(index)}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1, marginTop: 8 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: '', value: '' })}
                        >
                          {`+ ${i18n.t('contactModal.buttons.addExtraInfo')}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                  {i18n.t('contactModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId ? `${i18n.t('contactModal.buttons.okEdit')}` : `${i18n.t('contactModal.buttons.okAdd')}`}
                  {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
