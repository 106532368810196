import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ContactDrawer from '../ContactDrawer';
import MessageInput from '../MessageInput';
import TicketHeader from '../TicketHeader';
import TicketInfo from '../TicketInfo';
import TicketActionButtons from '../TicketActionButtons';
import MessagesList from '../MessagesListV2';
import api, { createSocketIo } from '../../services/api';
import { Contact, Ticket } from '../../services/types';
import { ReplyMessageProvider } from '../../context/ReplyingMessage/ReplyingMessageContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import toastError from '../../errors/toastError';
import { Params, TicketResponse } from './types';
import useStyles from './styles';

export default function TicketComponent(): ReactElement {
  const { ticketId } = useParams() as Params;
  const history = useHistory();
  const classes = useStyles();

  const { isMobile } = useIsMobile();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<Contact>({} as Contact);
  const [ticket, setTicket] = useState<Ticket | undefined>();

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async (): Promise<void> => {
        try {
          const { data } = await api.get(`/tickets/${ticketId}`);

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = createSocketIo();

    socket.on('connect', () => socket.emit('joinChatBox', ticketId));

    socket.on('ticket', (data: TicketResponse) => {
      if (data.action === 'update') {
        setTicket(data.ticket);
      }

      if (data.action === 'delete') {
        toast.success('Ticket deleted sucessfully.');
        history.replace('/tickets');
      }
    });

    socket.on('contact', (data: TicketResponse) => {
      if (data.action === 'update') {
        setContact(prevState => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const handleGoBack = useCallback(() => {
    if (ticket) {
      if (ticket.isGroup) {
        history.replace('/groups');
      } else {
        if (ticket.status === 'closed') {
          history.replace('/resolved');
        }
        if (ticket.status === 'open') {
          history.replace('/tickets');
        }
      }
    }
  }, [ticket, history]);

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {isMobile && (
            <IconButton aria-label="Go Back" onClick={handleGoBack} style={{ margin: '12px' }}>
              <ArrowBackIcon fontSize="default" color="inherit" />
            </IconButton>
          )}
          <TicketInfo contact={contact} ticket={ticket} onClick={handleDrawerOpen} />
          {ticket && <TicketActionButtons ticket={ticket} />}
        </TicketHeader>
        <ReplyMessageProvider>
          {ticket && <MessagesList ticket={ticket} />}
          {ticket && <MessageInput ticketStatus={ticket.status} />}
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} contact={contact} loading={loading} />
    </div>
  );
}
