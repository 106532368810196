import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  contents: {
    padding: 0,
  },
  frame: {
    border: 'none',
    width: '100%',
    height: '99%',
  },
}));
