import React, { ReactElement, useCallback, useContext, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import useIsMounted from '../../hooks/useIsMounted';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ConfirmationModal from '../ConfirmationModal';
import TicketTransferModal from '../TicketTransferModal';
import { OwnProps } from './types';

export default function TicketOptionsMenu({ ticket, menuOpen, handleClose, anchorEl }: OwnProps): ReactElement {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const isMounted = useIsMounted();
  const { user } = useContext(AuthContext);

  const handleDeleteTicket = useCallback(() => {
    api.delete(`/tickets/${ticket.id}`).catch(err => toastError(err));
  }, [ticket]);

  const handleOpenConfirmationModal = useCallback(() => {
    setConfirmationOpen(true);
    handleClose();
  }, [setConfirmationOpen, handleClose]);

  const handleOpenTransferModal = useCallback(() => {
    setTransferTicketModalOpen(true);
    handleClose();
  }, [setTransferTicketModalOpen, handleClose]);

  const handleCloseTransferTicketModal = useCallback(() => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  }, [isMounted, setTransferTicketModalOpen]);

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {!ticket.isGroup ? (
          <MenuItem onClick={handleOpenTransferModal}>{i18n.t('ticketOptionsMenu.transfer')}</MenuItem>
        ) : null}
        {user.canDeleteTickets() ? (
          <MenuItem onClick={handleOpenConfirmationModal}>{i18n.t('ticketOptionsMenu.delete')}</MenuItem>
        ) : null}
      </Menu>
      {!ticket.isGroup ? (
        <TicketTransferModal
          modalOpen={transferTicketModalOpen}
          onClose={handleCloseTransferTicketModal}
          ticket={ticket}
        />
      ) : null}
      {user.canDeleteTickets() ? (
        <ConfirmationModal
          title={`${i18n.t('ticketOptionsMenu.confirmationModal.title')}${ticket.id} ${i18n.t(
            'ticketOptionsMenu.confirmationModal.titleFrom'
          )} ${ticket.contact?.name}?`}
          open={confirmationOpen}
          onClose={setConfirmationOpen}
          onConfirm={handleDeleteTicket}
        >
          {i18n.t('ticketOptionsMenu.confirmationModal.message')}
        </ConfirmationModal>
      ) : null}
    </>
  );
}
