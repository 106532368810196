import React, { ReactNode } from 'react';
import rules from '../../rules';
import { UserProfile } from '../../services/types';

interface CanProps {
  role: UserProfile;
  perform: string;
  data?: any;
  yes?: () => ReactNode;
  no?: () => ReactNode;
}

function check(role: UserProfile, action: string, data: any): boolean {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }
  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }
  const dynamicPermissions = permissions.dynamic;
  if (dynamicPermissions) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }
    return permissionCondition(data);
  }
  return false;
}

export function Can({ role, perform, data, yes = () => <></>, no = () => <></> }: CanProps): any {
  return check(role, perform, data) ? yes() : no();
}
