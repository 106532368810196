/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createStyles, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import whatsBackground from '../../assets/wa-background.png';

const styles = (theme: Theme) =>
  createStyles({
    messagesListWrapper: {
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },

    messagesList: {
      backgroundImage: `url(${whatsBackground})`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '20px 20px 20px 20px',
      overflowY: 'scroll',
    },

    circleLoading: {
      color: green[500],
      position: 'absolute',
      opacity: '70%',
      top: 0,
      left: '50%',
      marginTop: 12,
    },

    messageLeft: {
      marginRight: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 600,
      height: 'auto',
      display: 'block',
      position: 'relative',
      '&:hover #messageActionsButton': {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
      },

      whiteSpace: 'pre-wrap',
      backgroundColor: '#ffffff',
      color: '#303030',
      alignSelf: 'flex-start',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
      boxShadow: '0 1px 1px #b3b3b3',
    },

    quotedContainerLeft: {
      margin: '-3px -80px 6px -6px',
      overflow: 'hidden',
      backgroundColor: '#f0f0f0',
      borderRadius: '7.5px',
      display: 'flex',
      position: 'relative',
    },

    quotedMsg: {
      padding: 10,
      maxWidth: 300,
      height: 'auto',
      display: 'block',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
    },

    quotedSideColorLeft: {
      flex: 'none',
      width: '4px',
      backgroundColor: '#6bcbef',
    },

    messageRight: {
      marginLeft: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 600,
      height: 'auto',
      display: 'block',
      position: 'relative',
      '&:hover #messageActionsButton': {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
      },

      whiteSpace: 'pre-wrap',
      backgroundColor: '#dcf8c6',
      color: '#303030',
      alignSelf: 'flex-end',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
      boxShadow: '0 1px 1px #b3b3b3',
    },

    quotedContainerRight: {
      margin: '-3px -80px 6px -6px',
      overflowY: 'hidden',
      backgroundColor: '#cfe9ba',
      borderRadius: '7.5px',
      display: 'flex',
      position: 'relative',
    },

    quotedMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: 'auto',
      whiteSpace: 'pre-wrap',
    },

    quotedSideColorRight: {
      flex: 'none',
      width: '4px',
      backgroundColor: '#35cd96',
    },

    messageActionsButton: {
      display: 'none',
      position: 'relative',
      color: '#999',
      zIndex: 1,
      backgroundColor: 'inherit',
      opacity: '90%',
      '&:hover, &.Mui-focusVisible': { backgroundColor: 'inherit' },
    },

    messageContactName: {
      display: 'flex',
      color: '#6bcbef',
      fontWeight: 500,
    },

    textContentItem: {
      overflowWrap: 'break-word',
      padding: '3px 80px 6px 6px',
      fontSize: 13,
    },

    textContentItemDeleted: {
      fontStyle: 'italic',
      color: 'rgba(0, 0, 0, 0.36)',
      overflowWrap: 'break-word',
      padding: '3px 80px 6px 6px',
    },

    messageMedia: {
      objectFit: 'cover',
      width: 250,
      height: 200,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },

    timestamp: {
      fontSize: 12,
      position: 'absolute',
      bottom: 0,
      right: 5,
      color: '#999',
    },

    dailyTimestamp: {
      alignItems: 'center',
      textAlign: 'center',
      alignSelf: 'center',
      width: '110px',
      backgroundColor: '#e1f3fb',
      margin: theme.spacing(1),
      borderRadius: '10px',
      boxShadow: '0 1px 1px #b3b3b3',
    },

    dailyTimestampText: {
      color: '#808888',
      alignSelf: 'center',
      fontSize: 12,
    },

    ticketSeparator: {
      alignItems: 'center',
      textAlign: 'center',
      alignSelf: 'center',
      width: '110px',
      backgroundColor: '#e1f3fb',
      margin: theme.spacing(1),
      borderRadius: '10px',
      boxShadow: '0 1px 1px #b3b3b3',
    },

    ticketSeparatorText: {
      color: '#808888',
      alignSelf: 'center',
      fontSize: 12,
    },

    ackIcons: {
      fontSize: 12,
      verticalAlign: 'middle',
      marginLeft: 4,
    },

    deletedIcon: {
      fontSize: 12,
      verticalAlign: 'middle',
      marginRight: 4,
    },

    ackDoneAllIcon: {
      color: green[500],
      fontSize: 12,
      verticalAlign: 'middle',
      marginLeft: 4,
    },

    downloadMedia: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'inherit',
      padding: 10,
    },
  });

export default styles;
