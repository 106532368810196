import React, { ReactElement } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { OwnProps } from './types';
import useStyles from './styles';

const ListItemLink = (props: OwnProps): ReactElement => {
  const { icon, primary, to, className } = props;
  const classes = useStyles();
  return (
    <li>
      <ListItem button className={className} onClick={to}>
        {icon ? <ListItemIcon className={classes.iconMenu}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
