import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Form, Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ContactField from '../../components/ContactField';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import Title from '../../components/Title';
import toastError from '../../errors/toastError';
import useIsMounted from '../../hooks/useIsMounted';
import api from '../../services/api';
import { Contact, isContact, ResponseContactsUser } from '../../services/types';
import { i18n } from '../../translate/i18n';
import ButtonOk from './ButtonOk';
import useStyles from './styles';
import { FormValues, Params } from './types';

const initialValues: FormValues = {
  contact: '',
};

export default function UserContacts(): ReactElement {
  const { userId } = useParams() as Params;
  const classes = useStyles();
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  const [contactsResponse, setContactsResponse] = useState<ResponseContactsUser>({
    hasMore: true,
    count: 0,
    contacts: [],
  } as ResponseContactsUser);

  const validation = useMemo(() => {
    return Yup.object().shape({
      contact: Yup.object().required('Obrigatório'),
    });
  }, []);

  const handleSaveTicket = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (isContact(values.contact)) {
        const contactData = {
          ...values.contact,
          userId: Number(userId),
        };

        const alreadyExists = contactsResponse.contacts.find(contact => contact.id === contactData.id);

        if (alreadyExists) {
          toast.error(i18n.t('userContacts.toasts.insert'));
          if (isMounted.current) {
            actions.setSubmitting(false);
          }
          return;
        }

        api
          .put(`/contacts/${contactData.id}`, contactData)
          .then(() => {
            setContactsResponse(prevState => ({
              ...prevState,
              contacts: [contactData, ...prevState.contacts],
            }));

            toast.success(i18n.t('userContacts.toasts.insert'));
          })
          .catch(err => {
            toastError(err);
          })
          .finally(() => {
            actions.setFieldValue('contact', '');
            if (isMounted.current) {
              actions.setSubmitting(false);
            }
          });
      }
    },
    [isMounted, userId, contactsResponse.contacts]
  );

  const handleRemoveContactUser = useCallback((contact: Contact) => {
    const contactData = {
      ...contact,
      userId: null,
    };

    api
      .put(`/contacts/${contactData.id}`, contactData)
      .then(() => {
        setContactsResponse(prevState => ({
          ...prevState,
          contacts: [...prevState.contacts.filter(contactItem => contactItem.id !== contactData.id)],
        }));

        toast.success(i18n.t('userContacts.toasts.deleted'));
      })
      .catch(err => {
        toastError(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get<ResponseContactsUser>(`/contacts?userId=${userId}`)
      .then(response => {
        setContactsResponse(response.data);
      })
      .catch(err => {
        console.error(err);
        toastError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t('userContacts.title')}</Title>
      </MainHeader>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={handleSaveTicket}
      >
        <Form>
          <Paper className={classes.formContactPaper} variant="outlined">
            <ContactField name="contact" />

            <div style={{ marginTop: 15 }}>
              <ButtonOk />
            </div>
          </Paper>
        </Form>
      </Formik>

      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t('userContacts.table.name')}</TableCell>
              <TableCell align="center">{i18n.t('userContacts.table.whatsapp')}</TableCell>
              <TableCell align="center">{i18n.t('userContacts.table.email')}</TableCell>
              <TableCell align="center">{i18n.t('userContacts.table.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contactsResponse.contacts.map(contact => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Avatar src={contact.profilePicUrl} />
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleRemoveContactUser(contact)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={2} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}
