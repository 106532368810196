import React, { createContext } from 'react';
import useAuth from '../../hooks/useAuth.js';
import { LoggedUser } from '../../services/types';

/**
 * @type {{isAuth: boolean, handleLogin: initialValues.handleLogin, loading: boolean, handleLogout: initialValues.handleLogout, user: LoggedUser, userQueues: Queue[], userWhatsapps: Whatsapp[]}}
 */
const initialValues = {
  user: {},
  userQueues: [],
  userWhatsapps: [],
  loading: false,
  isAuth: false,
  handleLogin: () => {},
  handleLogout: () => {},
};

const AuthContext = createContext(initialValues);

const AuthProvider = ({ children }) => {
  const { loading, user, queues, whatsapps, isAuth, handleLogin, handleLogout } = useAuth();
  return (
    <AuthContext.Provider
      value={{
        loading,
        user: new LoggedUser(user),
        userQueues: queues,
        userWhatsapps: whatsapps,
        isAuth,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
