import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { i18n } from '../../translate/i18n';

export default function WhatsappField(props) {
    const { name, label, enabled = true, whatsapps } = props;
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    useEffect(() => {
        if (whatsapps[0]) {
            setFieldValue(props.name, whatsapps[0].id);
        }
    }, [props.name, whatsapps, setFieldValue]);
    return (
        <FormControl variant="outlined" margin="dense" error={!!meta.touched && !!meta.error} fullWidth>
            <InputLabel id={`${name}-label`}>{i18n.t(label)}</InputLabel>
            <Select labelId={`${name}-label`} id={name} name={name} labelWidth={90} {...field} disabled={!enabled}>
                {whatsapps.map(whatsapp => (
                    <MenuItem key={whatsapp.id} value={whatsapp.id}>
                        {whatsapp.name}
                    </MenuItem>
                ))}
            </Select>
            {!!meta.touched && !!meta.error ? <FormHelperText>{meta.error}</FormHelperText> : null}
        </FormControl>
    );
}
