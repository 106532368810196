import React, { ReactElement, useCallback, useContext, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import useIsMounted from '../../hooks/useIsMounted';
import api from '../../services/api';
import { Contact, isContact, isQueue } from '../../services/types';
import { i18n } from '../../translate/i18n';
import ContactField from '../ContactField';
import TicketPrepareModal from '../TicketPrepareModal';
import ButtonCancel from './ButtonCancel';
import ButtonOk from './ButtonOk';
import { FormValues, Props } from './types';

const initialValues: FormValues = {
  contact: '',
  queue: '',
};

const validation = Yup.object().shape({
  contact: Yup.object().required('Obrigatório'),
  queue: Yup.object().optional(),
});

export default function NewTicketModal({ modalOpen, onClose }: Props): ReactElement {
  const history = useHistory();
  const isMounted = useIsMounted();
  const selectedContact = useRef<Contact>();
  const [prepareModalOpen, setPrepareModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const handleClosePrepareModal = useCallback(() => {
    setPrepareModalOpen(false);
  }, []);

  const handleSaveTicket = useCallback(
    (values: FormValues, actions?: FormikHelpers<FormValues>) => {
      selectedContact.current = isContact(values.contact) ? values.contact : undefined;
      return api
        .post('/tickets', {
          status: 'open',
          userId: user.id,
          contactId: selectedContact.current?.id,
          queueId: isQueue(values.queue) ? values.queue.id : undefined,
        })
        .then(({ data: ticket }: AxiosResponse) => {
          history.replace(`/tickets/${ticket.id}`);
          onClose();
        })
        .catch((err: AxiosError) => {
          if (err.response?.data?.error === 'ERR_MANY_QUEUES_OR_CONNECTIONS') {
            onClose();
            setPrepareModalOpen(true);
          } else {
            toastError(err);
            onClose();
          }
        })
        .finally(() => {
          if (isMounted.current) {
            actions && actions.setSubmitting(false);
          }
        });
    },
    [onClose, history, isMounted, user.id]
  );

  const handleSelectPrepareModal = useCallback(
    (values: Pick<FormValues, 'queue'>): Promise<unknown> | undefined => {
      if (isContact(selectedContact.current) && isQueue(values.queue)) {
        return handleSaveTicket({ contact: selectedContact.current, ...values });
      }
      return undefined;
    },
    [handleSaveTicket]
  );

  return (
    <>
      <TicketPrepareModal
        open={prepareModalOpen}
        onClose={handleClosePrepareModal}
        onSelect={handleSelectPrepareModal}
      />
      <Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="paper">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={handleSaveTicket}
        >
          <Form>
            <DialogTitle id="form-dialog-title">{i18n.t('newTicketModal.title')}</DialogTitle>
            <DialogContent dividers>
              <ContactField name="contact" autoFocus />
            </DialogContent>
            <DialogActions>
              <ButtonCancel onClick={onClose} />
              <ButtonOk />
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </>
  );
}
