import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';

export default function ButtonOk(): ReactElement {
  const { isSubmitting } = useFormikContext();
  return (
    <ButtonWithSpinner variant="contained" type="submit" color="primary" loading={isSubmitting}>
      {i18n.t('transferTicketModal.buttons.ok')}
    </ButtonWithSpinner>
  );
}
