import { Avatar, CardHeader, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import { i18n } from '../../translate/i18n';

export default function TicketInfo({ contact, ticket, onClick }) {
  const { whatsApps } = useContext(WhatsAppsContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (ticket.whatsapp && whatsApps.length > 0) {
        const whatsapp = whatsApps.find(w => w.id === ticket.whatsapp.id);
        if (whatsapp) {
          const offline =
            whatsapp.status === 'qrcode' ||
            whatsapp.status === 'PAIRING' ||
            whatsapp.status === 'DISCONNECTED' ||
            whatsapp.status === 'TIMEOUT' ||
            whatsapp.status === 'OPENING';
          setConnectionWarning(offline);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [ticket, whatsApps]);

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      disableTypography={true}
      avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
      title={
        <Typography variant="body2" display="block" noWrap={true}>
          {`${contact.name} #${ticket.id}`}
        </Typography>
      }
      subheader={
        <>
          {ticket.whatsapp ? (
            <Typography
              variant="body2"
              color={connectionWarning ? 'error' : 'textSecondary'}
              display="inline"
              noWrap={false}
            >
              {`${i18n.t('messagesList.header.connection')} ${ticket.whatsapp.name}`}
            </Typography>
          ) : null}
          {ticket.whatsapp && ticket.user ? (
            <Typography variant="body2" color="textSecondary" display="inline" noWrap={false}>
              {' / '}
            </Typography>
          ) : null}
          {ticket.user ? (
            <Typography variant="body2" color="textSecondary" display="inline" noWrap={false}>
              {`${i18n.t('messagesList.header.assignedTo')} ${ticket.user.name}`}
            </Typography>
          ) : null}
        </>
      }
    />
  );
}
