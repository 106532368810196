import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonClose from './ButtonClose';
import { useStyles } from './styles';
import { State } from './types';

export default function NewsModal(): ReactElement {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [state, setState] = useState<State>({
    open: !user.newsRead,
    loading: false,
  });

  const handleClose = useCallback(() => {
    setState(prevState => ({ ...prevState, loading: true }));
    api
      .put(`/users/${user.id}/newsread`)
      .catch(err => toastError(err))
      .finally(() => {
        setState({ open: false, loading: false });
      });
  }, [user]);

  return (
    <Dialog open={state.open} onClose={handleClose} maxWidth="md" scroll="paper" fullWidth fullScreen>
      <DialogTitle>{i18n.t('newsModal.title')}</DialogTitle>
      <DialogContent classes={{ root: classes.contents }} dividers>
        <iframe
          title={i18n.t('newsModal.title')}
          className={classes.frame}
          src="https://docs.google.com/document/d/e/2PACX-1vTic5tvololIxACGhl0SYzWfAAw_S95Oe8cjmU0SHPM2Qux8ocOxldKKVHpI1VQmCcOv_VRtBnKkwR0/pub?embedded=true"
        />
      </DialogContent>
      <DialogActions>
        <ButtonClose onClick={handleClose} loading={state.loading} />
      </DialogActions>
    </Dialog>
  );
}
