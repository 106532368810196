import React, { ReactElement } from 'react';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';

import { useIsMobile } from '../../hooks/useIsMobile';
import { useTheme } from '../../hooks/useTheme';
import { useManagerMainMenu } from '../../context/ManagerMainMenuDrawer';

import MainListItems from '../MainListItems';

import logoDarkImg from '../../assets/logo_dark.png';
import logoLightImg from '../../assets/logo_light.png';

import { OwnProps } from './types';
import useStyles from '../styles';

export default function MainMenuDrawer({ window }: OwnProps): ReactElement {
  const { isMobile } = useIsMobile();
  const { theme } = useTheme();
  const { mainMenuOpen, handleCloseMainMenu } = useManagerMainMenu();
  const classes = useStyles();

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      container={isMobile ? container : undefined}
      variant={isMobile ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(classes.drawerPaper, !mainMenuOpen && classes.drawerPaperClose),
      }}
      open={mainMenuOpen}
      anchor="left"
      onClose={handleCloseMainMenu}
    >
      <div className={classes.toolbarIcon}>
        {mainMenuOpen && (
          <img
            src={theme === 'dark' ? logoLightImg : logoDarkImg}
            alt="Logo Atendimento Top"
            style={{ width: 120, margin: 20 }}
          />
        )}
      </div>
      <Divider />
      <List>
        <MainListItems closeMenuDrawer={handleCloseMainMenu} />
      </List>
      <Divider />
    </Drawer>
  );
}
