import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/Auth/AuthContext";

export default function useUserWhatsApps() {
    const {user} = useContext(AuthContext);
    const [whatsapps, setWhatsapps] = useState([]);
    useEffect(() => {
        const whatsappsMap = new Map();
        user.queues && user.queues.forEach(queue =>
            queue.whatsapps && queue.whatsapps.forEach(whatsapp => {
                if (!whatsappsMap.has(whatsapp.id)) {
                    whatsappsMap.set(whatsapp.id, {
                        id  : whatsapp.id,
                        name: whatsapp.name,
                    })
                    whatsappsMap.get(whatsapp.id).queues = new Map();
                }
                whatsappsMap.get(whatsapp.id)
                .queues
                .set(queue.id, {
                    id   : queue.id,
                    name : queue.name,
                    color: queue.color,
                });
            }));
        const whatsapps = [];
        for (const [, whatsappValue] of whatsappsMap) {
            const whatsapp = {
                ...whatsappValue,
                queues: [],
            };
            whatsapps.push(whatsapp);
            for (const [, queue] of whatsappValue.queues) {
                whatsapp.queues.push(queue);
            }
        }
        setWhatsapps(whatsapps);
    }, [
        user,
        setWhatsapps
    ]);
    return whatsapps;
}
