import React, { ReactElement, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { i18n } from '../../translate/i18n';

import { Props } from './types';

export default function WarningModal({ open, content, onClose, onConfirm, onCancel = () => {} }: Props): ReactElement {
  const [state, setState] = useState<'confirmed' | 'canceled'>();

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      onExited={() => {
        if (state === 'confirmed') {
          onConfirm();
        } else if (state === 'canceled') {
          onCancel();
        }
      }}
    >
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setState('canceled');
            onClose();
          }}
          color="secondary"
          variant="outlined"
          size="medium"
        >
          {i18n.t('contactModal.buttons.cancel')}
        </Button>
        <Button
          onClick={() => {
            setState('confirmed');
            onClose();
          }}
          color="primary"
          variant="contained"
          size="medium"
        >
          {i18n.t('warningModal.buttons.okConfirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
