import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Badge } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

import { AuthContext } from '../../context/Auth/AuthContext';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import { useIsMobile } from '../../hooks/useIsMobile';

import { Can } from '../../components/Can';
import { i18n } from '../../translate/i18n';
import ListItemLink from '../ListItemLink';
import ListItemTicketsLink from '../ListItemTicketsLink';
import ListItemTicketsGroupLink from '../ListItemTicketsGroupLink';

import { OwnProps } from './types';
import { Queue } from '../../services/types';

export default function MainListItems({ closeMenuDrawer }: OwnProps): ReactElement {
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const userQueueIds = useMemo(() => (user ? user.queues.map((q: Queue) => q.id) : []), [user]);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats: any) => {
          return (
            whats.status === 'qrcode' ||
            whats.status === 'PAIRING' ||
            whats.status === 'DISCONNECTED' ||
            whats.status === 'TIMEOUT' ||
            whats.status === 'OPENING'
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const handleNavigationMenuDrawer = useCallback(
    (routePage: string) => {
      history.replace(routePage);
      if (isMobile) {
        closeMenuDrawer();
      }
    },
    [closeMenuDrawer, history, isMobile]
  );

  return (
    <div>
      <ListItemLink
        to={() => handleNavigationMenuDrawer('/')}
        primary="Dashboard"
        icon={<DashboardOutlinedIcon color="primary" />}
      />
      <ListItemLink
        to={() => handleNavigationMenuDrawer('/connections')}
        primary={i18n.t('mainDrawer.listItems.connections')}
        icon={
          <Badge badgeContent={connectionWarning ? '!' : 0} color="error">
            <SyncAltIcon color="primary" />
          </Badge>
        }
      />
      <ListItemTicketsLink userQueueIds={userQueueIds} onNavigationMenuDrawer={handleNavigationMenuDrawer} />
      <ListItemLink
        to={() => handleNavigationMenuDrawer('/resolved')}
        primary={i18n.t('mainDrawer.listItems.resolved')}
        icon={<CheckCircleIcon color="primary" />}
      />
      <ListItemTicketsGroupLink userQueueIds={userQueueIds} onNavigationMenuDrawer={handleNavigationMenuDrawer} />
      <ListItemLink
        to={() => handleNavigationMenuDrawer('/contacts')}
        primary={i18n.t('mainDrawer.listItems.contacts')}
        icon={<ContactPhoneOutlinedIcon color="primary" />}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader inset>{i18n.t('mainDrawer.listItems.administration')}</ListSubheader>
            <ListItemLink
              to={() => handleNavigationMenuDrawer('/users')}
              primary={i18n.t('mainDrawer.listItems.users')}
              icon={<PeopleAltOutlinedIcon color="primary" />}
            />
            <ListItemLink
              to={() => handleNavigationMenuDrawer('/queues')}
              primary={i18n.t('mainDrawer.listItems.queues')}
              icon={<AccountTreeOutlinedIcon color="primary" />}
            />
            <ListItemLink
              to={() => handleNavigationMenuDrawer('/settings')}
              primary={i18n.t('mainDrawer.listItems.settings')}
              icon={<SettingsOutlinedIcon color="primary" />}
            />
          </>
        )}
      />
    </div>
  );
}
