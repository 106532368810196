import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import toastError from '../../errors/toastError';
import { distinctFilter, flatMapReducer } from '../../helpers/array';
import api, { createSocketIo } from '../../services/api';
import { i18n } from '../../translate/i18n';

export default function useAuth() {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [queues, setQueues] = useState([]);
  const [whatsapps, setWhatsapps] = useState([]);

  api.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      }
      return config;
    },
    error => Promise.reject(error)
  );

  api.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { data } = await api.post('/auth/refresh_token');
        if (data) {
          localStorage.setItem('token', JSON.stringify(data.token));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (isAuth && user?.id && !user?.reloaded) {
      setLoading(true);
      api
        .get(`/users/${user.id}`)
        .then(res => {
          setUser({ ...res.data, reloaded: true });
        })
        .catch(err => console.error('Erro ao buscar usuário logado!', err))
        .finally(() => setLoading(false));
    }
  }, [isAuth, user, setLoading, setUser]);

  useEffect(() => {
    if (user.queues) {
      setQueues(user.queues.filter(distinctFilter((q, oq) => q.id === oq.id)));
      setWhatsapps(
        user.queues
          .map(q => q.whatsapps)
          .reduce(flatMapReducer(), [])
          .filter(distinctFilter((w, ow) => w.id === ow.id))
      );
    }
  }, [user, setQueues, setWhatsapps]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    (async () => {
      if (token) {
        try {
          const { data } = await api.post('/auth/refresh_token');
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
        } catch (err) {
          toastError(err);
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const socket = createSocketIo();
    socket.on('user', data => {
      if (data.action === 'update' && data.user.id === user.id) {
        setUser(data.user);
      }
    });
  }, [user]);

  const handleLogin = useCallback(
    async userData => {
      setLoading(true);
      try {
        const { data } = await api.post('/auth/login', userData);
        localStorage.setItem('token', JSON.stringify(data.token));
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        setUser(data.user);
        setIsAuth(true);
        toast.success(i18n.t('auth.toasts.success'));
        history.replace('/tickets');
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    },
    [setLoading, setUser, setIsAuth, history]
  );

  const handleLogout = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete('/auth/logout');
      setIsAuth(false);
      setUser({});
      localStorage.removeItem('token');
      api.defaults.headers.Authorization = undefined;
      setLoading(false);
      history.replace('/login');
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }, [setLoading, setIsAuth, setUser, history]);

  return { isAuth, user, queues, whatsapps, loading, handleLogin, handleLogout };
}
