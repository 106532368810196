import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useField, useFormikContext } from 'formik';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { isUser, User } from '../../services/types';
import { i18n } from '../../translate/i18n';
import { Props } from './types';

const filterOptions = createFilterOptions<User>({
  trim: true,
});

export default function UserField(props: Props): ReactElement {
  const { name, required = false, autoFocus = false } = props;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<User>(props);
  const [options, setOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    if (searchParam.length < 3) {
      setLoading(false);
      return () => {};
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      api
        .get('/users', {
          params: { searchParam },
        })
        .then(res => res.data)
        .then(({ users }) => setOptions(users))
        .catch(err => toastError(err))
        .finally(() => setLoading(false));
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchParam]);

  return (
    <Autocomplete
      getOptionLabel={(option: string | User | null) => (isUser(option) ? `${option.name}` : '')}
      value={field.value}
      onChange={(e: ChangeEvent<unknown>, newValue: string | User | null) => {
        isUser(newValue) && setFieldValue(name, newValue);
      }}
      options={options}
      filterOptions={filterOptions}
      noOptionsText={i18n.t('userField.noOptions')}
      loading={loading}
      freeSolo
      fullWidth
      autoHighlight
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.t('userField.fieldLabel')}
          variant="outlined"
          required={required}
          autoFocus={autoFocus}
          onChange={e => setSearchParam(e.target.value)}
          helperText={meta.touched && meta.error}
          error={meta.touched && !!meta.error}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
