import React, { ReactElement, useContext } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
// import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import QueueFieldComponent from '../QueueField';
import { Props } from './types';

export default function WhatsappsQueuesField({ name, label, autoFocus }: Props): ReactElement {
  // const { whatsAppsQueues } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  return <QueueFieldComponent name={name} label={label} queues={user.queues || []} autoFocus={autoFocus} />;
}
