import {useCallback, useState} from "react";
import toastError from "../../errors/toastError";

function storedFirstResolver(value, defaultValue) {
    return value !== null && value !== undefined ? value : defaultValue;
}

export function useLocalStorage(key, defaultValue, resolver = storedFirstResolver) {
	const [storedValue, setStoredValue] = useState(() => {
        let value;
        try {
			value = localStorage.getItem(key);
            if (value) {
                return resolver(JSON.parse(value), defaultValue);
            } else {
                value = resolver(null, defaultValue);
                localStorage.setItem(key, JSON.stringify(value));
            }
		} catch (error) {
			toastError(error);
            value = resolver(null, defaultValue);
            localStorage.setItem(key, JSON.stringify(value));
		}
        return value;
	});

    const setValue = useCallback(value => {
        try {
            setStoredValue(storedValue => {
                const valueToStore = resolver(value instanceof Function ? value(storedValue) : value, defaultValue);
                localStorage.setItem(key, JSON.stringify(valueToStore));
                return valueToStore;
            });
        } catch (error) {
            toastError(error);
        }
    }, [key, defaultValue, resolver, setStoredValue]);

    return [storedValue, setValue];
}
