import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Queue } from '../../services/types';
import { i18n } from '../../translate/i18n';
import TicketsList from '../TicketsList';
import TicketsQueueSelect from '../TicketsQueueSelect';
import useStyles from './styles';

export default function TicketsManager(): ReactElement {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [searchParam, setSearchParam] = useState('');
  const userQueueIds = user.queues.map((q: Queue) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const handleSearch = useCallback(e => {
    const searchedTerm = e.target.value.toLowerCase();
    setSearchParam(searchedTerm);
  }, []);

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <Typography variant="h6" color="inherit" style={{ paddingTop: 5 }}>
          {i18n.t('tickets.tabs.closed.title')}
        </Typography>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.serachInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            placeholder={i18n.t('tickets.search.placeholder')}
            type="search"
            onChange={handleSearch}
          />
        </div>
        <TicketsQueueSelect
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values: any) => setSelectedQueueIds(values)}
        />
      </Paper>
      <TicketsList
        status="closed"
        showAll
        selectedQueueIds={selectedQueueIds}
        searchParam={searchParam}
        isGroups={false}
      />
    </Paper>
  );
}
