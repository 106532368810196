import React, { useState, useContext, FunctionComponent } from 'react';

import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { parseISO, format, isSameDay } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

import { i18n } from '../../translate/i18n';

import api from '../../services/api';
import { Ticket } from '../../services/types';

import { AuthContext } from '../../context/Auth/AuthContext';
import { useManagerDrawerQueue } from '../../context/ManagerDrawerQueue';

import toastError from '../../errors/toastError';

import useIsMounted from '../../hooks/useIsMounted';

import ButtonWithSpinner from '../ButtonWithSpinner';
import MarkdownWrapper from '../MarkdownWrapper';

const useStyles = makeStyles(() => ({
  ticket: {
    position: 'relative',
  },
  pendingTicket: {
    cursor: 'unset',
  },
  noTicketsDiv: {
    display: 'flex',
    height: '100px',
    margin: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noTicketsText: {
    textAlign: 'center',
    color: 'rgb(104, 121, 146)',
    fontSize: '14px',
    lineHeight: '1.4',
  },
  noTicketsTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0px',
  },
  contactNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastMessageTime: {
    justifySelf: 'flex-end',
  },
  closedBadge: {
    alignSelf: 'center',
    justifySelf: 'flex-end',
    marginRight: 32,
    marginLeft: 'auto',
  },
  contactLastMessage: {
    paddingRight: 20,
  },
  newMessagesCount: {
    alignSelf: 'center',
    marginRight: 8,
    marginLeft: 'auto',
  },
  badgeStyle: {
    color: 'white',
    backgroundColor: green[500],
  },
  acceptButton: {
    position: 'absolute',
    left: '50%',
  },
  ticketQueueColor: {
    flex: 'none',
    width: '8px',
    height: '100%',
    position: 'absolute',
    top: '0%',
    left: '0%',
  },
}));

interface TicketListItemProps {
  ticket: Ticket;
  readOnly?: boolean;
}

const TicketListItem: FunctionComponent<TicketListItemProps> = ({ ticket, readOnly }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams<{ ticketId: string }>();
  const isMounted = useIsMounted();
  const { user } = useContext(AuthContext);

  const { handleCloseDrawerOnAccept } = useManagerDrawerQueue();

  const handleAcepptTicket = async (id: string | number) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: 'open',
        userId: user?.id,
      });
      handleCloseDrawerOnAccept();
    } catch (err) {
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.replace(`/tickets/${id}`);
  };

  const handleSelectTicket = (id: string | number) => {
    if (ticket.isGroup) {
      history.replace(`/groups/${id}`);
    } else if (ticket.status === 'closed') {
      history.replace(`/resolved/${id}`);
    } else {
      history.replace(`/tickets/${id}`);
    }
  };

  return (
    <>
      <ListItem
        dense
        button
        onClick={() => {
          if (readOnly || ticket.status === 'pending') return;
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId ? +ticketId === ticket.id : false}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === 'pending',
        })}
      >
        <Tooltip arrow placement="right" title={ticket.queue?.name || 'Sem fila'}>
          <span style={{ backgroundColor: ticket.queue?.color || '#7C7C7C' }} className={classes.ticketQueueColor} />
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography noWrap component="span" variant="body2" color="textPrimary">
                {ticket.contact?.name}
              </Typography>
              {ticket.status === 'closed' && (
                <Badge className={classes.closedBadge} badgeContent="closed" color="primary" />
              )}
              {ticket.lastMessage && (
                <Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}</>
                  )}
                </Typography>
              )}
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {ticket.lastMessage ? <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper> : <br />}
              </Typography>

              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
        {ticket.status === 'pending' && !readOnly && (
          <ButtonWithSpinner
            color="primary"
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={() => handleAcepptTicket(ticket.id)}
          >
            {i18n.t('ticketsList.buttons.accept')}
          </ButtonWithSpinner>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

TicketListItem.defaultProps = {
  readOnly: false,
};

export default TicketListItem;
