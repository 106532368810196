function referenceComparator<T>(v: T, o: T): boolean {
  return v === o;
}

export function flatMapReducer<T>(): (acc: T[], curr: T[]) => T[] {
  return (acc: T[], curr: T[]) => acc.concat(curr);
}

export function distinctFilter<T>(
  comparator: (value: T, other: T) => boolean = referenceComparator
): (v: T, i: number, self: T[]) => boolean {
  return (v: T, i: number, self: T[]) => i === self.findIndex(o => comparator(v, o));
}
