import React, { ReactElement } from 'react';

import { useIsMobile } from '../../hooks/useIsMobile';

import DesktopChat from './DesktopChat';
import MobileChat from './MobileChat';

import useStyles from './styles';

const Chat = (): ReactElement => {
  const classes = useStyles();
  const { isMobile } = useIsMobile();
  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>{isMobile ? <MobileChat /> : <DesktopChat />}</div>
    </div>
  );
};

export default Chat;
