import React from "react";
import Button from "@material-ui/core/Button";
import {i18n} from "../../translate/i18n";
import {useFormikContext} from "formik";

export default function ButtonCancel({onClick}) {
    const {isSubmitting} = useFormikContext();
    return (
        <Button
            onClick={onClick}
            color="secondary"
            disabled={isSubmitting}
            variant="outlined"
        >
            {i18n.t("ticketPrepareModal.buttons.cancel")}
        </Button>
    );
}
