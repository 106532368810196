import React, { Component, MouseEvent, ReactNode } from 'react';
import { AxiosResponse } from 'axios';
import { Socket } from 'socket.io-client';
import { format, isSameDay, parseISO } from 'date-fns';
import clsx from 'clsx';

import { Button, CircularProgress, Divider, IconButton } from '@material-ui/core';
import { AccessTime, Block, Done, DoneAll, ExpandLess, ExpandMore, GetApp } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import { i18n } from '../../translate/i18n';
import toastError from '../../errors/toastError';
import api, { createSocketIo } from '../../services/api';
import { Message, ResponseMessage, ResponseTicket, Ticket } from '../../services/types';

import MarkdownWrapper from '../MarkdownWrapper';
import MessageOptionsMenu from '../MessageOptionsMenu';
import ModalImageCors from '../ModalImageCors';

import styles from './styles';
import { Props, State } from './types';

class MessagesList extends Component<Props, State> {
  scrollViewRef: HTMLDivElement | null = null;

  ticketsHistory?: Ticket[];

  canSeeHistory: boolean;

  socket?: Socket;

  constructor(props: Props) {
    super(props);
    const {
      ticket: { status, isGroup },
    } = props;
    this.canSeeHistory = ['open', 'pending'].includes(status) && !isGroup;
    this.state = {
      messages: [],
      pageNumber: 1,
      hasMore: true,
      loading: false,
      ticket: props.ticket,
      currentTicket: props.ticket,
    };
  }

  componentDidMount(): void {
    if (this.scrollViewRef) {
      this.scrollViewRef.dataset.position = 'bottom';
      this.scrollViewRef.addEventListener('scroll', this.handleScroll);
    }
    this.fetch(() => {
      this.listenMessages();
    });
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    const { ticket } = this.props;
    return nextProps.ticket.id !== ticket.id || nextState !== this.state;
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { ticket: prevTicket } = prevProps;
    const { ticket } = this.props;
    const {
      ticket: { status, isGroup },
    } = this.props;
    const { currentTicket: prevCurrentTicket } = prevState;
    const { currentTicket } = this.state;
    this.canSeeHistory = ['open', 'pending'].includes(status) && !isGroup;
    if (prevTicket.id !== ticket.id) {
      if (this.scrollViewRef) {
        this.scrollViewRef.dataset.position = 'bottom';
      }
      this.ticketsHistory = undefined;
      this.dispatchReset({ ticket, currentTicket: ticket }, () => {
        this.fetch(() => {
          this.listenMessages();
        });
      });
    } else if (ticket !== currentTicket && prevCurrentTicket !== currentTicket) {
      if (this.scrollViewRef && this.scrollViewRef.scrollHeight === this.scrollViewRef.clientHeight) {
        this.loadMore();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
    if (this.scrollViewRef) {
      this.scrollViewRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  listenMessages = (): void => {
    if (this.socket) {
      this.socket.disconnect();
    }
    const { ticket } = this.state;
    if (ticket) {
      this.socket = createSocketIo();
      this.socket.on('connect', () => {
        if (this.socket) {
          this.socket.emit('joinChatBox', `${ticket.id}`);
        }
      });
      this.socket.on('appMessage', (data: any) => {
        if (data.action === 'create') {
          if (this.scrollViewRef) {
            this.scrollViewRef.dataset.position = 'bottom';
          }
          this.dispatchAddMessages({ messages: [data.message] }, () => {
            this.autoScroll();
          });
        }
        if (data.action === 'update') {
          this.dispatchAddMessages({ messages: [data.message] });
        }
      });
    }
  };

  computeMessages = (type: 'old' | 'new', prevMessages: Message[], messages?: Message[]): Message[] => {
    const newMessages: Message[] = [];
    const oldMessages: Message[] = [...prevMessages];
    (messages || []).forEach(message => {
      const i = oldMessages.findIndex(m => m.id === message.id);
      if (i !== -1) {
        oldMessages[i] = message;
      } else {
        newMessages.push(message);
      }
    });
    if (type === 'new') {
      return [...oldMessages, ...newMessages];
    }
    return [...newMessages, ...oldMessages];
  };

  dispatchLoadMessages = (
    {
      messages,
      pageNumber,
      hasMore,
      currentTicket,
    }: Pick<State, 'messages' | 'pageNumber' | 'hasMore' | 'currentTicket'>,
    callback?: () => void
  ): void => {
    this.setState(
      prevState => ({
        messages: this.computeMessages('old', prevState.messages, messages),
        pageNumber,
        hasMore,
        currentTicket,
      }),
      callback
    );
  };

  dispatchAddMessages = ({ messages }: Pick<State, 'messages'>, callback?: () => void): void => {
    this.setState(
      prevState => ({
        messages: this.computeMessages('new', prevState.messages, messages),
      }),
      callback
    );
  };

  dispatchReset = ({ ticket, currentTicket }: Pick<State, 'ticket' | 'currentTicket'>, callback?: () => void): void => {
    this.setState(
      {
        messages: [],
        pageNumber: 1,
        hasMore: true,
        currentMessage: undefined,
        ticket,
        currentTicket,
      },
      callback
    );
  };

  handleOpenMessageOptionsMenu = (e: MouseEvent<HTMLButtonElement>, message: Message): void => {
    const anchorEl = e.currentTarget;
    const currentMessage = message;
    this.setState(prevState => ({ ...prevState, anchorEl, currentMessage }));
  };

  handleCloseMessageOptionsMenu = (): void => {
    const anchorEl = undefined;
    this.setState(prevState => ({ ...prevState, anchorEl }));
  };

  fetchMessages = (): Promise<ResponseMessage> => {
    const { currentTicket, pageNumber } = this.state;
    return api
      .get<ResponseMessage>(`/messages/${currentTicket?.id}`, {
        params: { pageNumber },
      })
      .then(({ data }) => data);
  };

  fetchTicketsHistory = (): Promise<Ticket[]> => {
    const {
      ticket: { contact },
    } = this.state;
    if (this.canSeeHistory) {
      if (this.ticketsHistory) {
        return Promise.resolve(this.ticketsHistory);
      }
      return api
        .get<ResponseTicket>(`/tickets?pageNumber=1&contactId=${contact?.id}`)
        .then(({ data }: AxiosResponse<ResponseTicket>) => data.tickets)
        .then((th: Ticket[]) => {
          this.ticketsHistory = th;
          return th;
        });
    }
    return Promise.resolve([]);
  };

  resolveNextPage = ([data, th]: [ResponseMessage, Ticket[]]): Promise<[Message[], number, boolean, Ticket]> => {
    const { currentTicket, pageNumber } = this.state;
    if (!data.hasMore && th.length > 0) {
      const ticketIndex = th.findIndex(t => t === currentTicket);
      if (ticketIndex === -1) {
        return Promise.resolve([data.messages, 0, true, th[0]]);
      }
      if (ticketIndex + 1 <= th.length - 1) {
        return Promise.resolve([data.messages, 0, true, th[ticketIndex + 1]]);
      }
    }
    return Promise.resolve([data.messages, pageNumber, data.hasMore, currentTicket]);
  };

  fetch = (callback?: () => void): void => {
    const { currentTicket: ticket } = this.state;
    if (ticket) {
      this.setState({ loading: true }, () => {
        Promise.all([this.fetchMessages(), this.fetchTicketsHistory()])
          .then(([data, th]: [ResponseMessage, Ticket[]]) => this.resolveNextPage([data, th]))
          .then(([messages, pageNumber, hasMore, currentTicket]: [Message[], number, boolean, Ticket]) => {
            return new Promise<void>(res => {
              this.dispatchLoadMessages({ messages, pageNumber, hasMore, currentTicket }, () => {
                this.autoScroll();
                res();
              });
            });
          })
          .catch(err => toastError(err))
          .finally(() => {
            this.setState({ loading: false }, () => {
              callback && callback();
            });
          });
      });
    }
  };

  loadMore = (): void => {
    this.setState(
      ({ pageNumber }) => {
        return { pageNumber: pageNumber + 1 };
      },
      () => {
        this.fetch();
      }
    );
  };

  handleScroll = (e: any): void => {
    const { hasMore, loading } = this.state;
    if (!hasMore) {
      return;
    }
    const el = e.currentTarget as HTMLDivElement;
    const scrollTop = el.scrollTop;
    if (scrollTop === 0) {
      el.dataset.position = 'top';
      el.dataset.prevScrollHeight = `${el.scrollHeight}`;
      el.scrollTo({
        behavior: 'smooth',
        top: 1,
      });
    } else if (el.clientHeight + scrollTop === el.scrollHeight) {
      el.dataset.position = 'bottom';
    } else {
      el.dataset.position = 'middle';
      el.dataset.prevScrollHeight = `${el.scrollHeight}`;
    }
    if (loading) {
      return;
    }
    if (scrollTop === 0) {
      this.loadMore();
    }
  };

  autoScroll = (): void => {
    if (this.scrollViewRef) {
      if (!this.scrollViewRef.dataset.position || this.scrollViewRef.dataset.position === 'bottom') {
        this.scrollViewRef.scrollTo({
          top: this.scrollViewRef.scrollHeight,
        });
      } else if (this.scrollViewRef.dataset.prevScrollHeight) {
        this.scrollViewRef.scrollTop =
          this.scrollViewRef.scrollHeight - parseFloat(this.scrollViewRef.dataset.prevScrollHeight);
      }
    }
  };

  checkMessageMedia = (message: Message): ReactNode => {
    const { classes } = this.props;
    if (message.mediaType === 'image') {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    }
    if (message.mediaType === 'audio') {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={message.mediaUrl} type="audio/ogg" />
        </audio>
      );
    }
    if (message.mediaType === 'video') {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return <video className={classes.messageMedia} src={message.mediaUrl} controls />;
    }
    return (
      <>
        <div className={classes.downloadMedia}>
          <Button startIcon={<GetApp />} color="primary" variant="outlined" target="_blank" href={message.mediaUrl}>
            Download
          </Button>
        </div>
        <Divider />
      </>
    );
  };

  renderMessageAck = (message: Message): ReactNode => {
    const { classes } = this.props;
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
    return <></>;
  };

  renderDailyTimestamps = (message: Message, index: number): ReactNode => {
    const state = this.state;
    const { classes } = this.props;
    if (index === 0) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>{format(parseISO(message.createdAt), 'dd/MM/yyyy')}</div>
          <div className={classes.dailyTimestampText}>
            <ExpandMore />
          </div>
        </span>
      );
    }
    if (index < state.messages.length - 1) {
      const messageDay = parseISO(message.createdAt);
      const previousMessage = state.messages[index - 1];
      const previousMessageDay = parseISO(previousMessage.createdAt);
      if (!isSameDay(messageDay, previousMessageDay) && message.ticketId === previousMessage.ticketId) {
        return (
          <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
            <div className={classes.dailyTimestampText}>{format(parseISO(message.createdAt), 'dd/MM/yyyy')}</div>
            <div className={classes.dailyTimestampText}>
              <ExpandMore />
            </div>
          </span>
        );
      }
    }
    return <></>;
  };

  renderMiddleTicketSeparator = (message: Message, index: number): ReactNode => {
    const { messages } = this.state;
    const { classes } = this.props;
    const now = new Date();
    if (index === 0) {
      return null;
    }
    const previousMessage = messages[index - 1];
    if (message.ticketId !== previousMessage.ticketId) {
      const createdAt = parseISO(message.createdAt);
      const day = isSameDay(now, createdAt) ? i18n.t('messagesList.today') : format(createdAt, 'dd/MM/yyyy');
      return (
        <div className={classes.ticketSeparator} key={`ticketSeparator-${previousMessage.id}`}>
          <div className={classes.ticketSeparatorText}>
            <ExpandLess />
          </div>
          <div className={classes.ticketSeparatorText}>{`#${previousMessage.ticketId}`}</div>
          <div className={classes.ticketSeparatorText}>{day}</div>
          <div className={classes.ticketSeparatorText}>
            <ExpandMore />
          </div>
        </div>
      );
    }
    return null;
  };

  renderLastTicketSeparator = (message: Message, index: number): ReactNode => {
    const { ticket, messages } = this.state;
    const { classes } = this.props;
    if (index === messages.length - 1 && message.ticketId !== ticket.id) {
      return (
        <div className={classes.ticketSeparator} key={`ticketSeparator-${message.id}`}>
          <div className={classes.ticketSeparatorText}>
            <ExpandLess />
          </div>
          <div className={classes.ticketSeparatorText}>{`#${message.ticketId}`}</div>
        </div>
      );
    }
    return null;
  };

  renderMessageDivider = (message: Message, index: number): ReactNode => {
    const state = this.state;
    if (index < state.messages.length && index > 0) {
      const messageUser = state.messages[index].fromMe;
      const previousMessageUser = state.messages[index - 1].fromMe;
      if (messageUser !== previousMessageUser) {
        return <span style={{ marginTop: 16 }} key={`divider-${message.id}`} />;
      }
    }
    return <></>;
  };

  renderQuotedMessage = (message: Message): ReactNode => {
    const { classes } = this.props;
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        />
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>{message.quotedMsg?.contact?.name}</span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  renderMessages = (): ReactNode => {
    const { ticket, messages } = this.state;
    const {
      classes,
      ticket: { isGroup },
    } = this.props;
    if (messages.length > 0) {
      return messages.map((message, index) => {
        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {this.renderMiddleTicketSeparator(message, index)}
              {this.renderDailyTimestamps(message, index)}
              {this.renderMessageDivider(message, index)}
              <div className={classes.messageLeft}>
                {message.ticketId === ticket.id && ticket.status === 'open' && (
                  <IconButton
                    size="small"
                    id="messageActionsButton"
                    disabled={message.isDeleted}
                    className={classes.messageActionsButton}
                    onClick={e => this.handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
                {isGroup && <span className={classes.messageContactName}>{message.contact?.name}</span>}
                {message.mediaUrl && this.checkMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && <Block color="disabled" fontSize="small" className={classes.deletedIcon} />}
                  {message.quotedMsg && this.renderQuotedMessage(message)}
                  <MarkdownWrapper>{message.body}</MarkdownWrapper>
                  <span className={classes.timestamp}>{format(parseISO(message.createdAt), 'HH:mm')}</span>
                </div>
              </div>
              {this.renderLastTicketSeparator(message, index)}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={message.id}>
            {this.renderMiddleTicketSeparator(message, index)}
            {this.renderDailyTimestamps(message, index)}
            {this.renderMessageDivider(message, index)}
            <div className={classes.messageRight}>
              {message.ticketId === ticket.id && ticket.status === 'open' && (
                <IconButton
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={e => this.handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
              )}
              {message.mediaUrl && this.checkMessageMedia(message)}
              <div
                className={clsx(classes.textContentItem, {
                  [classes.textContentItemDeleted]: message.isDeleted,
                })}
              >
                {message.isDeleted && <Block color="disabled" fontSize="small" className={classes.deletedIcon} />}
                {message.quotedMsg && this.renderQuotedMessage(message)}
                <MarkdownWrapper>{message.body}</MarkdownWrapper>
                <span className={classes.timestamp}>
                  {format(parseISO(message.createdAt), 'HH:mm')}
                  {this.renderMessageAck(message)}
                </span>
              </div>
            </div>
            {this.renderLastTicketSeparator(message, index)}
          </React.Fragment>
        );
      });
    }
    return <div>Say hello to your new contact!</div>;
  };

  render(): ReactNode {
    const { loading, anchorEl, messages, currentMessage } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.messagesListWrapper}>
        {currentMessage && (
          <MessageOptionsMenu
            message={currentMessage}
            anchorEl={anchorEl}
            menuOpen={Boolean(anchorEl)}
            handleClose={this.handleCloseMessageOptionsMenu}
            canDelete={currentMessage.fromMe}
          />
        )}
        <div
          className={classes.messagesList}
          ref={ref => {
            this.scrollViewRef = ref;
          }}
        >
          {messages.length > 0 ? this.renderMessages() : []}
        </div>
        {loading && (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MessagesList);
