import React, { ReactElement, createContext, useContext, ReactNode, useCallback } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';

type ManagerMainMenuContextData = {
  mainMenuOpen: boolean;
  handleToggleMainMenu: () => void;
  handleCloseMainMenu: () => void;
};

type ManagerDrawerQueueProviderProps = {
  children: ReactNode;
};

export const ManagerMainMenuContext = createContext({} as ManagerMainMenuContextData);

function ManagerMainMenuProvider({ children }: ManagerDrawerQueueProviderProps): ReactElement {
  const [mainMenuOpen, setMainMenuOpen] = useLocalStorage('drawerOpen', true);

  const handleToggleMainMenu = useCallback(() => {
    setMainMenuOpen(!mainMenuOpen);
  }, [setMainMenuOpen, mainMenuOpen]);

  const handleCloseMainMenu = useCallback(() => {
    setMainMenuOpen(false);
  }, [setMainMenuOpen]);

  return (
    <ManagerMainMenuContext.Provider
      value={{
        mainMenuOpen,
        handleToggleMainMenu,
        handleCloseMainMenu,
      }}
    >
      {children}
    </ManagerMainMenuContext.Provider>
  );
}

function useManagerMainMenu(): ManagerMainMenuContextData {
  const context = useContext(ManagerMainMenuContext);
  return context;
}

export { ManagerMainMenuProvider, useManagerMainMenu };
