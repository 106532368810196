import React, { useCallback, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/Auth/AuthContext';
import useIsMounted from '../../hooks/useIsMounted';
import { i18n } from '../../translate/i18n';
import QueueField from '../QueueField';
import ButtonCancel from './ButtonCancel';
import ButtonOk from './ButtonOk';
import { useStyles } from './styles';

/**
 * @typedef {FormikValues} FormValues
 * @property {string} queue
 */

/**
 * @type FormValues
 */
const initialValues = {
  queue: '',
};

const validation = Yup.object().shape({
  queue: Yup.object().required('Required'),
});

export default function TicketPrepareModal({ open, onClose, onSelect }) {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const { userQueues } = useContext(AuthContext);

  function handleClose() {
    onClose();
  }

  /**
   * @param {FormValues} values
   * @param {FormikHelpers<FormValues>} actions
   */
  const handleSubmit = useCallback((values, actions) => {
    const onSelectRes = onSelect(values);
    if (onSelectRes instanceof Promise) {
      onSelectRes.finally(() => {
        if (isMounted.current) {
          actions.setSubmitting(false);
        }
        onClose();
      });
    }
  }, [isMounted, onSelect, onClose]);

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">{i18n.t('ticketPrepareModal.title')}</DialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          <Form>
            <DialogContent dividers>
              <QueueField name="queue" label="ticketPrepareModal.form.queue" queues={userQueues} />
            </DialogContent>
            <DialogActions>
              <ButtonCancel onClick={handleClose} />
              <ButtonOk />
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}
