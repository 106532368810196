import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginRight: 6,
    flex: 'none',
    alignSelf: 'center',
    marginLeft: 'auto',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default useStyles;
