export interface Whatsapp {
  readonly id: number;
  readonly name: string;
  readonly qrcode: string;
  readonly hasSession: boolean;
  readonly status: string;
  readonly retries: number;
  readonly greetingMessage: string;
  readonly isDefault: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly queues: Queue[];
}

export function isWhatsapp(obj: unknown): obj is Whatsapp {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'name' in obj &&
    'qrcode' in obj &&
    'hasSession' in obj &&
    'status' in obj &&
    'retries' in obj &&
    'greetingMessage' in obj &&
    'isDefault' in obj &&
    'createdAt' in obj &&
    'updatedAt' in obj
  );
}

export interface Queue {
  readonly id: number;
  readonly name: string;
  readonly color: string;
  readonly greetingMessage: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly whatsapps: Whatsapp[];
}

export function isQueue(obj: unknown): obj is Queue {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'name' in obj &&
    'color' in obj &&
    'greetingMessage' in obj &&
    'createdAt' in obj &&
    'updatedAt' in obj
  );
}

export type UserProfile = 'user' | 'man' | 'admin';

export interface User {
  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly profile: UserProfile;
  readonly newsRead: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly queues: Queue[];
}

export function isUser(obj: unknown): obj is User {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'name' in obj &&
    'email' in obj &&
    'profile' in obj &&
    'newsRead' in obj &&
    'createdAt' in obj &&
    'updatedAt' in obj
  );
}

export interface ContactCustomField {
  readonly id: number;
  readonly name: string;
  readonly value: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface Contact {
  readonly id: number;
  readonly name: string;
  readonly number: string;
  readonly email: string;
  readonly profilePicUrl: string;
  readonly isGroup: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly whatsapp: Whatsapp;
  readonly userId?: number;
  readonly extraInfo: ContactCustomField[];
}

export function isContact(obj: unknown): obj is Contact {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'name' in obj &&
    'number' in obj &&
    'email' in obj &&
    'profilePicUrl' in obj &&
    'isGroup' in obj &&
    'createdAt' in obj &&
    'updatedAt' in obj
  );
}

export interface ResponseContactsUser {
  count: number;
  hasMore: boolean;
  contacts: Contact[];
}

export type TicketStatus = 'pending' | 'open' | 'closed';

export interface Ticket {
  readonly id: number;
  readonly status: TicketStatus;
  readonly unreadMessages: number;
  readonly lastMessage: string;
  readonly isGroup: boolean;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly user?: User;
  readonly contact?: Contact;
  readonly whatsapp?: Whatsapp;
  readonly queue?: Queue;
}

export interface Message {
  id: string;
  ack: number;
  body: string;
  contact: Contact;
  contactId: number;
  createdAt: string;
  fromMe: boolean;
  isDeleted: boolean;
  mediaType: string;
  mediaUrl: string;
  quotedMsg: Message;
  quotedMsgId: string;
  read: boolean;
  ticketId: number;
  updatedAt: string;
}

export interface ResponseTicket {
  count: number;
  hasMore: boolean;
  tickets: Ticket[];
}

export interface ResponseMessage {
  count: number;
  hasMore: boolean;
  messages: Message[];
  ticket: Ticket;
}

export interface WriteTicket extends Ticket {
  readonly userId?: number | null;
  readonly contactId?: number | null;
  readonly whatsappId?: number | null;
  readonly queueId?: number | null;
}

export class LoggedUser implements User {
  private data: User;

  constructor(data: User) {
    this.data = data;
  }

  get id(): number {
    return this.data.id;
  }

  get name(): string {
    return this.data.name;
  }

  get email(): string {
    return this.data.email;
  }

  get profile(): UserProfile {
    return this.data.profile;
  }

  get newsRead(): boolean {
    return this.data.newsRead;
  }

  get createdAt(): Date {
    return this.data.createdAt;
  }

  get updatedAt(): Date {
    return this.data.updatedAt;
  }

  get queues(): Queue[] {
    return this.data.queues;
  }

  public canSeeAllTickets(): boolean {
    return this.profile === 'admin' || this.profile === 'man';
  }

  public canDeleteTickets(): boolean {
    return this.profile === 'admin';
  }

  public canManageConnections(): boolean {
    return this.profile === 'admin';
  }
}
