import React, { createContext, PropsWithChildren } from 'react';

import useWhatsApps from '../../hooks/useWhatsApps';

import { Queue, Whatsapp } from '../../services/types';

interface WhatsAppsContextState {
  loading: boolean;
  whatsApps: Whatsapp[];
  whatsAppsQueues: Queue[];
  whatsAppsAllQueues: Queue[];
}

const initialValues: WhatsAppsContextState = {
  loading: false,
  whatsApps: [],
  whatsAppsQueues: [],
  whatsAppsAllQueues: [],
};

const WhatsAppsContext = createContext(initialValues);

const WhatsAppsProvider = ({ children }: PropsWithChildren<any>) => {
  const { loading, whatsApps, whatsAppsQueues, whatsAppsAllQueues } = useWhatsApps();
  return (
    <WhatsAppsContext.Provider value={{ loading, whatsApps, whatsAppsQueues, whatsAppsAllQueues }}>
      {children}
    </WhatsAppsContext.Provider>
  );
};

export { WhatsAppsContext, WhatsAppsProvider };
