import React, { ReactElement } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeAplicationProvider } from './hooks/useTheme';
import Routes from './routes';

import './styles.css';

export default function App(): ReactElement {
  return (
    <ThemeAplicationProvider>
      <Routes />
    </ThemeAplicationProvider>
  );
}
