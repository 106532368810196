const messages = {
  pt: {
    translations: {
      signup: {
        title: 'Cadastre-se',
        toasts: {
          success: 'Usuário criado com sucesso! Faça seu login!!!.',
          fail: 'Erro ao criar usuário. Verifique os dados informados.',
        },
        form: {
          name: 'Nome',
          email: 'Email',
          password: 'Senha',
        },
        buttons: {
          submit: 'Cadastrar',
          login: 'Já tem uma conta? Entre!',
        },
      },
      login: {
        greeting: 'Seja Bem Vindo ao Atendimento Top',
        title: 'Informe seus dados para acessar!',
        form: {
          email: 'Email',
          password: 'Senha',
        },
        buttons: {
          submit: 'Entrar',
          register: 'Não tem um conta? Cadastre-se!',
        },
      },
      auth: {
        toasts: {
          success: 'Login efetuado com sucesso!',
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: 'Tickets hoje: ',
          },
        },
      },
      connections: {
        title: 'Conexões',
        toasts: {
          deleted: 'Conexão com o WhatsApp excluída com sucesso!',
        },
        confirmationModal: {
          deleteTitle: 'Deletar',
          deleteMessage: 'Você tem certeza? Essa ação não pode ser revertida.',
          disconnectTitle: 'Desconectar',
          disconnectMessage: 'Tem certeza? Você precisará ler o QR Code novamente.',
        },
        buttons: {
          add: 'Adicionar WhatsApp',
          disconnect: 'desconectar',
          connect: 'Conectar',
          qrcode: 'QR CODE',
          newQr: 'Novo QR CODE',
          connecting: 'Conectando',
        },
        toolTips: {
          disconnected: {
            title: 'Falha ao iniciar sessão do WhatsApp',
            content:
              'Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code',
          },
          qrcode: {
            title: 'Esperando leitura do QR Code',
            content: "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: 'Conexão estabelecida!',
          },
          timeout: {
            title: 'A conexão com o celular foi perdida',
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: 'Número',
          status: 'Status',
          lastUpdate: 'Última atualização',
          default: 'Padrão',
          actions: 'Ações',
          session: 'Sessão',
        },
      },
      whatsappModal: {
        title: {
          add: 'Adicionar WhatsApp',
          edit: 'Editar WhatsApp',
        },
        form: {
          name: 'Número',
          default: 'Padrão',
        },
        buttons: {
          okAdd: 'Adicionar',
          okEdit: 'Salvar',
          cancel: 'Cancelar',
        },
        success: 'WhatsApp salvo com sucesso.',
      },
      qrCode: {
        message: 'Leia o QrCode para iniciar a sessão',
      },
      contacts: {
        title: 'Contatos',
        toasts: {
          deleted: 'Contato excluído com sucesso!',
        },
        searchPlaceholder: 'Pesquisar...',
        confirmationModal: {
          deleteTitle: 'Deletar ',
          importTitlte: 'Importar contatos',
          deleteMessage: 'Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.',
          importMessage: 'Deseja importas todos os contatos do telefone?',
        },
        buttons: {
          import: 'Importar Contatos',
          add: 'Adicionar Contato',
        },
        table: {
          name: 'Nome',
          whatsapp: 'WhatsApp',
          email: 'Email',
          actions: 'Ações',
        },
      },
      contactModal: {
        title: {
          add: 'Adicionar contato',
          edit: 'Editar contato',
        },
        form: {
          whatsappId: 'Conexão',
          mainInfo: 'Dados do contato',
          extraInfo: 'Informações adicionais',
          name: 'Nome',
          number: 'Número do Whatsapp',
          email: 'Email',
          extraName: 'Nome do campo',
          extraValue: 'Valor',
          titleWarningWhatsNumber: 'Atenção',
          primaryWarningWhatsNumber: 'O número do WhatsApp do cliente deve ser exatamente o número dele do WhasApp.',
          secondaryWarningWhatsNumber: '(Obs.: Existem contas que possuem o número 9 como nono digito e outras não)',
          tertiaryWarningWhatsNumber: 'Se o número do contato for do Brasil, ele deve inicar com 55.',
        },
        buttons: {
          addExtraInfo: 'Adicionar informação',
          okAdd: 'Adicionar',
          okEdit: 'Salvar',
          cancel: 'Cancelar',
        },
        success: 'Contato salvo com sucesso.',
      },
      queueModal: {
        title: {
          add: 'Adicionar fila',
          edit: 'Editar fila',
        },
        form: {
          name: 'Nome',
          color: 'Cor',
          greetingMessage: 'Mensagem de saudação',
        },
        buttons: {
          okAdd: 'Adicionar',
          okEdit: 'Salvar',
          cancel: 'Cancelar',
        },
      },
      userModal: {
        title: {
          add: 'Adicionar usuário',
          edit: 'Editar usuário',
        },
        form: {
          name: 'Nome',
          email: 'Email',
          password: 'Senha',
          profile: 'Perfil',
        },
        buttons: {
          okAdd: 'Adicionar',
          okEdit: 'Salvar',
          cancel: 'Cancelar',
        },
        success: 'Usuário salvo com sucesso.',
      },
      colorModal: {
        buttons: {
          okConfirm: 'Confirmar',
        },
      },
      warningModal: {
        buttons: {
          okConfirm: 'Confirmar',
          cancel: 'Cancelar',
        },
      },
      chat: {
        noTicketMessage: 'Selecione um ticket para começar a conversar.',
      },
      ticketsManager: {
        buttons: {
          newTicket: 'Novo',
        },
      },
      ticketsQueueSelect: {
        placeholder: 'Filas',
      },
      tickets: {
        toasts: {
          deleted: 'O ticket que você estava foi deletado.',
        },
        notification: {
          message: 'Mensagem de',
        },
        tabs: {
          open: { title: 'Inbox' },
          closed: { title: 'Resolvidos' },
          group: { title: 'Grupos' },
          search: { title: 'Busca' },
        },
        search: {
          placeholder: 'Buscar tickets e mensagens',
        },
        buttons: {
          showAll: 'Todos',
        },
      },
      transferTicketModal: {
        title: 'Transferir Ticket',
        option: {
          queue: 'Minhas Filas',
          other: 'Outras Filas',
          user: 'Para Usuário',
        },
        form: {
          queue: 'Fila',
          user: 'Usuário',
        },
        buttons: {
          ok: 'Transferir',
          cancel: 'Cancelar',
        },
      },
      ticketsList: {
        pendingHeader: 'Aguardando',
        assignedHeader: 'Atendendo',
        noTicketsTitle: 'Nada aqui!',
        noTicketsMessage: 'Nenhum ticket encontrado com esse status ou termo pesquisado',
        buttons: {
          accept: 'Aceitar',
        },
      },
      ticketPrepareModal: {
        title: 'Selecionar Fila',
        form: {
          queue: 'Fila',
        },
        buttons: {
          ok: 'Aceitar',
          cancel: 'Cancelar',
        },
      },
      newTicketModal: {
        title: 'Criar Ticket',
        fieldLabel: 'Digite para pesquisar o contato',
        add: 'Adicionar',
        buttons: {
          ok: 'Salvar',
          cancel: 'Cancelar',
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: 'Dashboard',
          connections: 'Conexões',
          tickets: 'Tickets',
          resolved: 'Resolvidos',
          groups: 'Grupos',
          contacts: 'Contatos',
          queues: 'Filas',
          administration: 'Administração',
          users: 'Usuários',
          settings: 'Configurações',
        },
        appBar: {
          user: {
            profile: 'Perfil',
            contacts: 'Meus Contatos',
            logout: 'Sair',
          },
          drawerQueue: {
            optionCloseDrawer: 'Fechar ao Aceitar',
          },
        },
      },
      notifications: {
        noTickets: 'Nenhuma notificação.',
      },
      queues: {
        title: 'Filas',
        table: {
          name: 'Nome',
          color: 'Cor',
          greeting: 'Mensagem de saudação',
          actions: 'Ações',
        },
        buttons: {
          add: 'Adicionar fila',
        },
        confirmationModal: {
          deleteTitle: 'Excluir',
          deleteMessage:
            'Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.',
        },
      },
      queueSelect: {
        inputLabel: 'Filas',
      },
      users: {
        title: 'Usuários',
        table: {
          name: 'Nome',
          email: 'Email',
          profile: 'Perfil',
          actions: 'Ações',
        },
        buttons: {
          add: 'Adicionar usuário',
        },
        toasts: {
          deleted: 'Usuário excluído com sucesso.',
        },
        confirmationModal: {
          deleteTitle: 'Excluir',
          deleteMessage:
            'Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.',
        },
      },
      userContacts: {
        title: 'Contatos do Usuário',
        table: {
          name: 'Nome',
          email: 'E-mail',
          whatsapp: 'WhatsApp',
          actions: 'Ações',
        },
        toasts: {
          insert: 'Contato do Usuário inserido com sucesso.',
          deleted: 'Contato do Usuário removido com sucesso.',
          error: 'É necessário informar o contato.',
        },
        buttons: {
          add: 'Add Contato do Usuário',
        },
      },
      settings: {
        success: 'Configurações salvas com sucesso.',
        title: 'Configurações',
        settings: {
          userCreation: {
            name: 'Criação de usuário',
            options: {
              enabled: 'Ativado',
              disabled: 'Desativado',
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: 'Atribuído à:',
          connection: 'Conexão:',
          buttons: {
            return: 'Retornar',
            resolve: 'Resolver',
            reopen: 'Reabrir',
            accept: 'Aceitar',
          },
        },
        today: 'Hoje',
      },
      messagesInput: {
        placeholderOpen: 'Digite uma mensagem',
        placeholderClosed: 'Reabra ou aceite esse ticket para enviar uma mensagem.',
        signMessage: 'Assinar',
      },
      contactDrawer: {
        header: 'Dados do contato',
        buttons: {
          edit: 'Editar contato',
        },
        extraInfo: 'Outras informações',
      },
      ticketOptionsMenu: {
        delete: 'Deletar',
        transfer: 'Transferir',
        confirmationModal: {
          title: 'Deletar o ticket do contato',
          message: 'Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.',
        },
        buttons: {
          delete: 'Excluir',
          cancel: 'Cancelar',
        },
      },
      confirmationModal: {
        buttons: {
          confirm: 'Ok',
          cancel: 'Cancelar',
        },
      },
      messageOptionsMenu: {
        delete: 'Deletar',
        reply: 'Responder',
        confirmationModal: {
          title: 'Apagar mensagem?',
          message: 'Esta ação não pode ser revertida.',
        },
      },
      userField: {
        fieldLabel: 'Digite para buscar usuários',
        noOptions: 'Nenhum usuário encontrado com esse nome',
      },
      newsModal: {
        title: 'Novidades',
        buttons: {
          close: 'Fechar',
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: 'Deve haver pelo menos um WhatsApp padrão.',
        ERR_NO_DEF_WAPP_FOUND: 'Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.',
        ERR_WAPP_NOT_INITIALIZED: 'Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.',
        ERR_WAPP_CHECK_CONTACT: 'Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões',
        ERR_WAPP_INVALID_CONTACT: 'Este não é um número de Whatsapp válido.',
        ERR_WAPP_DOWNLOAD_MEDIA: 'Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.',
        ERR_INVALID_CREDENTIALS: 'Erro de autenticação. Por favor, tente novamente.',
        ERR_SENDING_WAPP_MSG: 'Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.',
        ERR_DELETE_WAPP_MSG: 'Não foi possível excluir a mensagem do WhatsApp.',
        ERR_OTHER_OPEN_TICKET: 'Já existe um ticket aberto para este contato.',
        ERR_SESSION_EXPIRED: 'Sessão expirada. Por favor entre.',
        ERR_USER_CREATION_DISABLED: 'A criação do usuário foi desabilitada pelo administrador.',
        ERR_NO_PERMISSION: 'Você não tem permissão para acessar este recurso.',
        ERR_DUPLICATED_CONTACT: 'Já existe um contato com este número.',
        ERR_NO_SETTING_FOUND: 'Nenhuma configuração encontrada com este ID.',
        ERR_NO_CONTACT_FOUND: 'Nenhum contato encontrado com este ID.',
        ERR_NO_TICKET_FOUND: 'Nenhum ticket encontrado com este ID.',
        ERR_NO_USER_FOUND: 'Nenhum usuário encontrado com este ID.',
        ERR_NO_WAPP_FOUND: 'Nenhum WhatsApp encontrado com este ID.',
        ERR_CREATING_MESSAGE: 'Erro ao criar mensagem no banco de dados.',
        ERR_CREATING_TICKET: 'Erro ao criar ticket no banco de dados.',
        ERR_FETCH_WAPP_MSG: 'Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.',
        ERR_QUEUE_COLOR_ALREADY_EXISTS: 'Esta cor já está em uso, escolha outra.',
        ERR_WAPP_GREETING_REQUIRED: 'A mensagem de saudação é obrigatório quando há mais de uma fila.',
        ERR_MANY_QUEUES_OR_CONNECTIONS: 'Mais de uma fila/conexão configurados.',
      },
    },
  },
};

export { messages };
