import React, { ReactElement, createContext, useContext, ReactNode, useCallback } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';

type ManagerDrawerQueueContextData = {
  drawerQueueOpen: boolean;
  drawerQueueCloseOnAccept: boolean;
  handleToggleDrawer: () => void;
  handleCloseDrawer: () => void;
  handleToggleSwitchCloseOnAccept: (value: boolean) => void;
  handleCloseDrawerOnAccept: () => void;
};

type ManagerDrawerQueueProviderProps = {
  children: ReactNode;
};

export const ManagerDrawerQueueContext = createContext({} as ManagerDrawerQueueContextData);

function ManagerDrawerQueueProvider({ children }: ManagerDrawerQueueProviderProps): ReactElement {
  const [drawerQueueCloseOnAccept, setDrawerQueueCloseOnAccept] = useLocalStorage(
    'preferences:layout:drawerQueueCloseOnAccept',
    true
  );
  const [drawerQueueOpen, setDrawerQueueOpen] = useLocalStorage('preferences:layout:drawerQueueOpen', false);

  const handleToggleDrawer = useCallback(() => {
    setDrawerQueueOpen(!drawerQueueOpen);
  }, [setDrawerQueueOpen, drawerQueueOpen]);

  const handleCloseDrawer = useCallback(() => {
    setDrawerQueueOpen(false);
  }, [setDrawerQueueOpen]);

  const handleToggleSwitchCloseOnAccept = useCallback(
    (value: boolean) => {
      setDrawerQueueCloseOnAccept(value);
    },
    [setDrawerQueueCloseOnAccept]
  );

  const handleCloseDrawerOnAccept = useCallback(() => {
    if (drawerQueueCloseOnAccept) {
      handleCloseDrawer();
    }
  }, [drawerQueueCloseOnAccept, handleCloseDrawer]);

  return (
    <ManagerDrawerQueueContext.Provider
      value={{
        drawerQueueOpen,
        drawerQueueCloseOnAccept,
        handleToggleDrawer,
        handleCloseDrawer,
        handleToggleSwitchCloseOnAccept,
        handleCloseDrawerOnAccept,
      }}
    >
      {children}
    </ManagerDrawerQueueContext.Provider>
  );
}

function useManagerDrawerQueue(): ManagerDrawerQueueContextData {
  const context = useContext(ManagerDrawerQueueContext);
  return context;
}

export { ManagerDrawerQueueProvider, useManagerDrawerQueue };
