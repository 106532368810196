import { useCallback, useEffect, useState } from 'react';

import toastError from '../../errors/toastError';

import api from '../../services/api';
import { TicketStatus } from '../../services/types';

export interface UseTicketsProps {
  searchParam?: string;
  pageNumber?: number;
  status?: TicketStatus;
  date?: string;
  showAll?: boolean;
  queueIds?: string;
  withUnreadMessages?: string;
  isGroups?: boolean;
}

export default function useTickets({
  searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  withUnreadMessages,
  isGroups,
}: UseTicketsProps) {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      api
        .get('/tickets', {
          params: {
            searchParam,
            pageNumber,
            status,
            date,
            showAll,
            isGroups,
            queueIds,
            withUnreadMessages,
          },
        })
        .then(({ data }) => {
          setTickets(data.tickets);
          setHasMore(data.hasMore);
        })
        .catch(err => toastError(err))
        .finally(() => setLoading(false));
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, status, date, showAll, isGroups, queueIds, withUnreadMessages]);

  const backgroundRefresh = useCallback(() => {
    api
      .get('/tickets', {
        params: {
          searchParam,
          pageNumber,
          status,
          date,
          showAll,
          isGroups,
          queueIds,
          withUnreadMessages,
        },
      })
      .then(({ data }) => {
        setTickets(data.tickets);
        setHasMore(data.hasMore);
      })
      .catch(err => toastError(err));
  }, [searchParam, pageNumber, status, date, showAll, isGroups, queueIds, withUnreadMessages]);

  return { tickets, loading, hasMore, backgroundRefresh };
}
