import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
    height: `calc(100% - 48px)`,
    overflowY: 'hidden',
  },
  chatPapper: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
  },
  contactsWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  messagessWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  welcomeMsg: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
  },
}));

export default useStyles;
