import React, { ReactElement, useState, useContext, useEffect, useCallback } from 'react';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Badge } from '@material-ui/core';
import { useManagerDrawerQueue } from '../../context/ManagerDrawerQueue';
import { i18n } from '../../translate/i18n';
import TicketsQueueSelect from '../../components/TicketsQueueSelect';
import TicketsList from '../../components/TicketsList';
import { AuthContext } from '../../context/Auth/AuthContext';
import { createSocketIo } from '../../services/api';
import useStyles from './styles';

import api from '../../services/api';

const MenuQueueDrawer = (): ReactElement => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const {
    drawerQueueOpen,
    handleToggleDrawer,
    handleCloseDrawer,
    drawerQueueCloseOnAccept,
    handleToggleSwitchCloseOnAccept,
  } = useManagerDrawerQueue();

  const userQueueIds = user.queues.map((q: any) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [numberTicketsInQueue, setNumberTicketsInQueue] = useState(0);

  const getTickets = useCallback(() => {
    api
      .get('/tickets', {
        params: {
          status: 'pending',
          isGroups: false,
          queueIds: JSON.stringify(selectedQueueIds),
        },
      })
      .then(({ data }) => {
        setNumberTicketsInQueue(data.tickets ? data.tickets.length : 0);
      })
      .catch(err => {
        console.error(err);
      });
  }, [selectedQueueIds]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  useEffect(() => {
    const socket = createSocketIo();

    socket.on('connect', () => socket.emit('joinTickets', 'pending'));
    socket.on('ticket', (data: any) => {
      if (data.action === 'updateUnread' || data.action === 'update' || data.action === 'delete') {
        getTickets();
      }
    });

    socket.on('appMessage', (data: any) => {
      if (data.action === 'create') {
        getTickets();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [getTickets]);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleToggleDrawer}
        color="primary"
        style={{ marginLeft: '10px' }}
      >
        <Badge badgeContent={numberTicketsInQueue} color="secondary">
          <DynamicFeedIcon fontSize="default" color="inherit" />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={drawerQueueOpen}
        onClose={handleCloseDrawer}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaperSecondary,
        }}
      >
        <Paper square elevation={0} className={classes.ticketOptionsBox}>
          <TicketsQueueSelect
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(values: any) => setSelectedQueueIds(values)}
          />

          <FormControlLabel
            style={{ marginRight: 7, color: 'gray' }}
            label={i18n.t('mainDrawer.appBar.drawerQueue.optionCloseDrawer')}
            labelPlacement="start"
            control={
              <Switch
                size="small"
                checked={drawerQueueCloseOnAccept}
                onChange={e => {
                  handleToggleSwitchCloseOnAccept(e.target.checked);
                }}
                name="signOption"
                color="primary"
              />
            }
          />
        </Paper>

        <TicketsList status="pending" selectedQueueIds={selectedQueueIds} showAll searchParam="" isGroups={false} />
      </Drawer>
    </>
  );
};

export default MenuQueueDrawer;
