import React, { useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { SettingsContext } from '../../context/Settings/SettingsContext';

import { useTheme } from '../../hooks/useTheme';

import Chart from './Chart';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 240,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    background: 'transparent',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { theme } = useTheme();
  const { dashboardIframeSrc } = useContext(SettingsContext);
  return (
    <>
      {dashboardIframeSrc ? (
        <iframe
          src={`${dashboardIframeSrc}${theme === 'dark' ? '#theme=night' : ''}`}
          className={classes.iframe}
          frameborder="0"
          allowtransparency
        />
      ) : (
        // <div>Hello world</div>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
