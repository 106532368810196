import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useFormikContext} from "formik";
import {i18n} from "../../translate/i18n";
import {useButtonOkStyles} from "./styles";

export default function ButtonOk({onClick}) {
    const classes = useButtonOkStyles();
    const {isSubmitting} = useFormikContext();
    return (
        <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            className={classes.btnWrapper}
        >
            {i18n.t("ticketPrepareModal.buttons.ok")}
            {isSubmitting && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </Button>
    );
}
