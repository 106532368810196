import { useContext, useEffect, useReducer, useState } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import { distinctFilter, flatMapReducer } from '../../helpers/array';
import api, { createSocketIo } from '../../services/api';
import { Queue } from '../../services/types';

const reducer = (state: any, action: any) => {
  if (action.type === 'LOAD_WHATSAPPS') {
    const whatsApps = action.payload;
    return [...whatsApps];
  }
  if (action.type === 'UPDATE_WHATSAPPS') {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s: any) => s.id === whatsApp.id);
    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = whatsApp;
      return [...state];
    }
    return [whatsApp, ...state];
  }
  if (action.type === 'UPDATE_SESSION') {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s: any) => s.id === whatsApp.id);
    if (whatsAppIndex !== -1) {
      state[whatsAppIndex].status = whatsApp.status;
      state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
      state[whatsAppIndex].qrcode = whatsApp.qrcode;
      state[whatsAppIndex].retries = whatsApp.retries;
      state[whatsAppIndex].hasSession = whatsApp.hasSession;
      return [...state];
    }
    return [...state];
  }
  if (action.type === 'DELETE_WHATSAPPS') {
    const whatsAppId = action.payload;
    const whatsAppIndex = state.findIndex((s: any) => s.id === whatsAppId);
    if (whatsAppIndex !== -1) {
      state.splice(whatsAppIndex, 1);
    }
    return [...state];
  }
  if (action.type === 'RESET') {
    return [];
  }
  return state;
};

/**
 * @returns {{loading: boolean, whatsApps: Whatsapp[], whatsAppsQueues: Queue[]}}
 */
export default function useWhatsApps() {
  const { user } = useContext(AuthContext);
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [whatsAppsQueues, setWhatsAppsQueues] = useState<Queue[]>([]);
  const [whatsAppsAllQueues, setWhatsAppsAllQueues] = useState<Queue[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    api
      .get('/whatsapp')
      .then(({ data }) => isMounted && dispatch({ type: 'LOAD_WHATSAPPS', payload: data }))
      .catch(err => isMounted && toastError(err))
      .finally(() => isMounted && setLoading(false));
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setWhatsAppsQueues(
      whatsApps
        ?.map((w: any) => w.queues)
        .reduce(flatMapReducer(), [])
        .filter(distinctFilter((wq: any, oWq: any) => wq.id === oWq.id)) || []
    );
  }, [setWhatsAppsQueues, whatsApps]);

  useEffect(() => {
    let isMounted = true;
    if (user && user.queues) {
      (async () => {
        try {
          const { data } = await api.get<Queue[]>('/queue');
          if (isMounted) {
            const userQueuesId = user.queues.filter(item => item.id).map(item => +item.id);
            const queuesFiltered = data.filter(item => !userQueuesId.includes(+item.id));
            setWhatsAppsAllQueues(queuesFiltered);
          }
        } catch (err) {
          console.error('Erro ao buscar as filas no backend!', err);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    let isMounted = true;
    const socket = createSocketIo();
    socket.on('whatsapp', (data: any) => {
      if (data.action === 'update') {
        isMounted && dispatch({ type: 'UPDATE_WHATSAPPS', payload: data.whatsapp });
      }
    });
    socket.on('whatsapp', (data: any) => {
      if (data.action === 'delete') {
        isMounted && dispatch({ type: 'DELETE_WHATSAPPS', payload: data.whatsappId });
      }
    });
    socket.on('whatsappSession', (data: any) => {
      if (data.action === 'update') {
        isMounted && dispatch({ type: 'UPDATE_SESSION', payload: data.session });
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return { whatsApps, whatsAppsQueues, whatsAppsAllQueues, loading };
}
