import React, { createContext, useEffect, PropsWithChildren } from 'react';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import api, { createSocketIo } from '../../services/api';

interface Settings extends Record<string, string> {
  userCreation: 'enabled' | 'disabled';
  timeReopenClosedTicket: string;
  signMessages: 'yes' | 'no' | 'always-yes' | 'always-no';
  showPendingTicketsWithoutQueue: 'enabled' | 'disabled';
  showClosedTicketsWithoutQueue: 'enabled' | 'disabled';
  dashboardIframeSrc: string;
}

type SettingsResponse = { key: keyof Settings; value: string }[];

export const defaultSettings: Settings = {
  userCreation: 'enabled', // enabled/disabled
  timeReopenClosedTicket: '10', // number in minutes
  signMessages: 'yes', // yes/no/always-yes/always-no
  showPendingTicketsWithoutQueue: 'enabled', // enabled/disabled
  showClosedTicketsWithoutQueue: 'enabled', // enabled/disabled
  dashboardIframeSrc: '', // empty string or url
};

export const SettingsContext = createContext(defaultSettings);

export function SettingsProvider({ children }: PropsWithChildren<any>) {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings) as [
    Settings,
    (s: Settings | ((ps: Settings) => Settings)) => void
  ];
  useEffect(() => {
    let isMounted = true;
    api
      .get<SettingsResponse>('/settings')
      .then(
        res =>
          isMounted &&
          setSettings(prevSettings => {
            const newSettings = { ...prevSettings };
            res.data.forEach(({ key, value }) => {
              newSettings[key] = value;
            });
            return newSettings;
          })
      )
      .catch(err => console.error('Error trying to fetch settings from server!', err));
    return () => {
      isMounted = false;
    };
  }, [setSettings]);
  useEffect(() => {
    let isMounted = true;
    const socket = createSocketIo();
    socket.on('settings', ({ action, setting: { key, value } }: any) => {
      if (action === 'update') {
        isMounted &&
          setSettings(prevSettings => {
            const newSettings = { ...prevSettings };
            newSettings[key] = value;
            return newSettings;
          });
      }
    });
    return () => {
      isMounted = false;
    };
  }, [setSettings]);
  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
}
