import React, { useContext } from 'react';
import { Route as RouterRoute, RouteProps as RouterRouteProps, Redirect } from 'react-router-dom';

import { AuthContext } from '../context/Auth/AuthContext';
import BackdropLoading from '../components/BackdropLoading';

export interface RouteProps extends RouterRouteProps {
  isPrivate?: boolean;
}

export default function Route({ isPrivate = false, ...rest }: RouteProps) {
  const { isAuth, loading } = useContext(AuthContext);
  if (loading) {
    return <BackdropLoading />;
  }
  if (!isAuth && isPrivate) {
    return <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />;
  }
  if (isAuth && !isPrivate) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }
  return <RouterRoute {...rest} />;
}
