import React, { ReactElement, useContext } from 'react';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import QueueFieldComponent from '../QueueField';
import { Props } from './types';

export default function WhatsappsAllQueuesField({ name, label, autoFocus }: Props): ReactElement {
  const { whatsAppsAllQueues } = useContext(WhatsAppsContext);

  return <QueueFieldComponent name={name} label={label} queues={whatsAppsAllQueues} autoFocus={autoFocus} />;
}
