import axios from 'axios';
import { io } from 'socket.io-client';

let baseURL = `${process.env.REACT_APP_BACKEND_URL}`.replace('undefined', '');

// eslint-disable-next-line no-restricted-globals
if (location.hostname.indexOf('.atendimentotop.com.br') > -1) {
  // eslint-disable-next-line no-restricted-globals
  const prefix = location.hostname.replace('.atendimentotop.com.br', '');
  // eslint-disable-next-line no-restricted-globals
  baseURL = `${location.protocol}//${prefix}-api.atendimentotop.com.br:${location.port}`;
  // eslint-disable-next-line no-restricted-globals
} else if (location.hostname === 'atendimentotop.com.br') {
  // eslint-disable-next-line no-restricted-globals
  baseURL = `${location.protocol}//api.atendimentotop.com.br`;
}

export function createSocketIo() {
  return io(baseURL, {
    reconnectionDelay: 5000,
    secure: true,
  });
}

const api = axios.create({
  baseURL,
  withCredentials: true,
});

export default api;
