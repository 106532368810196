import React, { ReactElement } from 'react';
import { useParams } from 'react-router';

import ContactsList from './ContactsList';
import Chat from './Chat';

import { Params } from '../types';

export default function MobileChat(): ReactElement {
  const { ticketId } = useParams() as Params;
  return (
    <>
      {ticketId ? (
        <>
          <Chat />
        </>
      ) : (
        <ContactsList />
      )}
    </>
  );
}
