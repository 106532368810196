import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import { MoreVert, Replay } from '@material-ui/icons';
import useIsMounted from '../../hooks/useIsMounted';
import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import TicketOptionsMenu from '../TicketOptionsMenu';
import ButtonWithSpinner from '../ButtonWithSpinner';
import toastError from '../../errors/toastError';
import { AuthContext } from '../../context/Auth/AuthContext';

import { Props } from './types';
import useStyles from './styles';

export default function TicketActionButtons({ ticket }: Props): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [anchorEl, setAnchorEl] = useState<null | HTMLAnchorElement>(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);

  const handleOpenTicketOptionsMenu = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseTicketOptionsMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleUpdateTicketStatus = useCallback(
    (e, status, userId) => {
      setLoading(true);
      api
        .put(`/tickets/${ticket.id}`, {
          status,
          userId: userId || null,
        })
        .then(() => {
          if (status === 'open') {
            history.replace(`/tickets/${ticket.id}`);
          } else {
            history.replace('/tickets');
          }
        })
        .catch(err => toastError(err))
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    },
    [setLoading, history, ticket, isMounted]
  );

  return (
    <div className={classes.actionButtons}>
      {ticket.status === 'closed' && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          size="small"
          onClick={(e: any) => handleUpdateTicketStatus(e, 'open', user?.id)}
        >
          {i18n.t('messagesList.header.buttons.reopen')}
        </ButtonWithSpinner>
      )}
      {ticket.status === 'open' && (
        <>
          {!ticket.isGroup ? (
            <>
              <ButtonWithSpinner
                loading={loading}
                startIcon={<Replay />}
                size="small"
                onClick={(e: any) => handleUpdateTicketStatus(e, 'pending', null)}
              >
                {i18n.t('messagesList.header.buttons.return')}
              </ButtonWithSpinner>
              <ButtonWithSpinner
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={(e: any) => handleUpdateTicketStatus(e, 'closed', user?.id)}
              >
                {i18n.t('messagesList.header.buttons.resolve')}
              </ButtonWithSpinner>
            </>
          ) : null}
          {!ticket.isGroup || user.canDeleteTickets() ? (
            <>
              <IconButton onClick={handleOpenTicketOptionsMenu}>
                <MoreVert />
              </IconButton>
              <TicketOptionsMenu
                ticket={ticket}
                anchorEl={anchorEl}
                menuOpen={ticketOptionsMenuOpen}
                handleClose={handleCloseTicketOptionsMenu}
              />
            </>
          ) : null}
        </>
      )}
      {ticket.status === 'pending' && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={(e: any) => handleUpdateTicketStatus(e, 'open', user?.id)}
        >
          {i18n.t('messagesList.header.buttons.accept')}
        </ButtonWithSpinner>
      )}
    </div>
  );
}
