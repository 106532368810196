import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    flexGrow: 1,
    margin: theme.spacing(0, 0, 2, 0),
  },
  panel: {
    padding: theme.spacing(2),
  },
}));
