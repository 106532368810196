import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import TicketsManager from '../../../../components/TicketsManager';

import useStyles from '../../styles';

export default function ContactsList(): ReactElement {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.contactsWrapper}>
        <TicketsManager />
      </Grid>
    </Grid>
  );
}
