import React, { ReactElement, useState, useCallback } from 'react';
import { Button, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

import { ChromePicker } from 'react-color';

import { i18n } from '../../translate/i18n';

import { Props } from './types';

export default function ColorPicker({ onChange, currentColor, handleClose, open }: Props): ReactElement {
  const [selectedColor, setSelectedColor] = useState(currentColor);

  const handleChange = useCallback(color => {
    setSelectedColor(color.hex);
    // handleClose();
  }, []);

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
      <ChromePicker
        styles={{ default: { picker: { width: '100%' } } }}
        color={selectedColor}
        onChange={handleChange}
        onChangeComplete={color => onChange(color.hex)}
      />
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          {i18n.t('colorModal.buttons.okConfirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
