import React, { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import { i18n } from '../../translate/i18n';

export default function ButtonOk(): ReactElement {
  const { isSubmitting } = useFormikContext();
  return (
    <ButtonWithSpinner variant="contained" type="submit" color="primary" loading={isSubmitting}>
      {i18n.t('userContacts.buttons.add')}
    </ButtonWithSpinner>
  );
}
