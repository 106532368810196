import React, { ReactElement } from 'react';
import { useField, useFormikContext } from 'formik';
import { Contact } from '../../services/types';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';

export default function ButtonOk(): ReactElement {
  const { isSubmitting } = useFormikContext();
  const [field] = useField<Contact>({ name: 'contact' });
  return (
    <ButtonWithSpinner variant="contained" type="submit" color="primary" loading={isSubmitting} disabled={!field.value}>
      {i18n.t('newTicketModal.buttons.ok')}
    </ButtonWithSpinner>
  );
}
