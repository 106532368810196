import { makeStyles, Theme } from '@material-ui/core';

const drawerWidthLg = '30%';
const drawerWidthMd = '50%';
const drawerWidthSm = '90%';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidthLg,
    },
    flexShrink: 0,
  },
  drawerPaperSecondary: {
    [theme.breakpoints.down('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidthLg,
    },
  },
  ticketOptionsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(1),
  },
}));

export default useStyles;
