const theme = {
  palette: {
    primary: { main: '#007E34', light: '#007E34', dark: '#33975c' },
    background: { default: '#fff', paper: '#fafafa' },
    type: 'light',
  },
  typography: {
    fontSize: 12,
  },
};

export default theme;
