import React, { ReactElement } from 'react';

import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import { i18n } from '../../translate/i18n';

export default function WarningWhatsNumber(): ReactElement {
  return (
    <Alert severity="warning" style={{ marginTop: 10, marginBottom: 10 }}>
      <strong>{i18n.t('contactModal.form.titleWarningWhatsNumber')}</strong>
      <Typography variant="body1">* {i18n.t('contactModal.form.primaryWarningWhatsNumber')}</Typography>
      <Typography variant="body1">{i18n.t('contactModal.form.secondaryWarningWhatsNumber')}</Typography>
      <Typography variant="body1">* {i18n.t('contactModal.form.tertiaryWarningWhatsNumber')}</Typography>
    </Alert>
  );
}
