import React, { ReactElement, useState, useContext, ChangeEvent, useCallback, FormEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import logoImg from '../../assets/logo.png';

import { i18n } from '../../translate/i18n';
import { AuthContext } from '../../context/Auth/AuthContext';

import useStyles from './styles';

export default function Login(): ReactElement {
  const classes = useStyles();

  const [user, setUser] = useState({ email: '', password: '' });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    },
    [user]
  );

  const handlSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      handleLogin(user);
    },
    [handleLogin, user]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logoImg} alt="Logo Atendimento Top" style={{ width: 250, marginBottom: 30 }} />

        <Typography component="h1" variant="h5" color="textSecondary" style={{ marginBottom: 30 }}>
          {i18n.t('login.greeting')}
        </Typography>
        <Typography component="h3" variant="h6" color="textSecondary" style={{ marginBottom: 10 }}>
          {i18n.t('login.title')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18n.t('login.form.email')}
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18n.t('login.form.password')}
            type="password"
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {i18n.t('login.buttons.submit')}
          </Button>
          <Grid container>
            <Grid item>
              <Link variant="body2" component={RouterLink} to="/signup">
                {i18n.t('login.buttons.register')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>{/* <Copyright /> */}</Box>
    </Container>
  );
}
