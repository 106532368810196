import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ptBR, Localization } from '@material-ui/core/locale';

import getTheme from '../../styles/theme';

interface IThemeContext {
  toggleTheme: () => void;
  theme: string;
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

const ThemeAplicationProvider: React.FC = ({ children }: React.PropsWithChildren<any>) => {
  const [locale, setLocale] = useState<Localization>({} as Localization);

  const [theme, setTheme] = useState(() => {
    const themeSaved = localStorage.getItem('preferences:layout:theme');

    if (themeSaved) {
      return themeSaved;
    }
    return 'light';
  });

  useEffect(() => {
    const i18nlocale = localStorage.getItem('i18nextLng');
    if (i18nlocale) {
      const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
      if (browserLocale === 'ptBR') {
        setLocale(ptBR);
      }
    }
  }, []);

  const toggleTheme = (): void => {
    if (theme === 'dark') {
      setTheme('light');
      localStorage.setItem('preferences:layout:theme', 'light');
    } else {
      setTheme('dark');
      localStorage.setItem('preferences:layout:theme', 'dark');
    }
  };

  const currentTheme = useMemo(() => {
    const themeData = createMuiTheme(
      {
        ...getTheme(theme),
      },
      locale
    );

    return themeData;
  }, [theme, locale]);

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useTheme(): IThemeContext {
  const context = useContext(ThemeContext);

  return context;
}

export { ThemeAplicationProvider, useTheme };
