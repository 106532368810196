import React, { FunctionComponent, UIEvent, useContext, useEffect, useReducer, useState } from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { AuthContext } from '../../context/Auth/AuthContext';
import { SettingsContext } from '../../context/Settings/SettingsContext';

import useTickets from '../../hooks/useTickets';

import { createSocketIo } from '../../services/api';
import { Contact, Ticket, TicketStatus } from '../../services/types';

import { i18n } from '../../translate/i18n';

import TicketListItem from '../TicketListItem';
import TicketsListSkeleton from '../TicketsListSkeleton';

const useStyles = makeStyles(theme => ({
  ticketsListWrapper: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ticketsList: {
    flex: 1,
    overflowY: 'scroll',
    ...(theme as any).scrollbarStyles,
    borderTop: `2px solid rgba(0, 0, 0, 0.12)`,
  },
  ticketsListHeader: {
    color: theme.palette.text.primary,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ticketsCount: {
    fontWeight: 'normal',
    color: theme.palette.text.primary,
    marginLeft: '8px',
    fontSize: '14px',
  },
  noTicketsText: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '1.4',
  },
  noTicketsTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0px',
  },
  noTicketsDiv: {
    display: 'flex',
    height: '100px',
    margin: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Action {
  type: string;
  payload?: number | Contact | Ticket | Ticket[];
}

function reducer(state: Ticket[], action: Action) {
  if (action.type === 'SET_TICKETS') {
    return action.payload as Ticket[];
  }
  if (action.type === 'LOAD_TICKETS') {
    const newTickets = action.payload as Ticket[];
    const newState = [...state];
    newTickets.forEach(ticket => {
      const ticketIndex = newState.findIndex(t => t.id === ticket.id);
      if (ticketIndex !== -1) {
        newState[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          newState.unshift(newState.splice(ticketIndex, 1)[0]);
        }
      } else {
        newState.push(ticket);
      }
    });
    return newState;
  }
  if (action.type === 'RESET_UNREAD') {
    const ticketId = action.payload as number;
    const ticketIndex = state.findIndex(t => t.id === ticketId);
    if (ticketIndex !== -1) {
      const newState = [...state];
      newState[ticketIndex] = { ...newState[ticketIndex], unreadMessages: 0 };
      return newState;
    }
  }
  if (action.type === 'UPDATE_TICKET') {
    const ticket = action.payload as Ticket;
    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    const newState = [...state];
    if (ticketIndex !== -1) {
      newState[ticketIndex] = ticket;
    } else {
      newState.unshift(ticket);
    }
    return newState;
  }
  if (action.type === 'UPDATE_TICKET_UNREAD_MESSAGES') {
    const ticket = action.payload as Ticket;
    const ticketIndex = state.findIndex(t => t.id === ticket.id);
    const newState = [...state];
    if (ticketIndex !== -1) {
      newState[ticketIndex] = ticket;
      newState.unshift(newState.splice(ticketIndex, 1)[0]);
    } else {
      newState.unshift(ticket);
    }
    return newState;
  }
  if (action.type === 'UPDATE_TICKET_CONTACT') {
    const contact = action.payload as Contact;
    const ticketIndex = state.findIndex(t => t?.contact?.id === contact.id);
    if (ticketIndex !== -1) {
      const newState = [...state];
      newState[ticketIndex] = { ...newState[ticketIndex], contact };
      return newState;
    }
  }
  if (action.type === 'DELETE_TICKET') {
    const ticketId = action.payload as number;
    const ticketIndex = state.findIndex(t => t.id === ticketId);
    if (ticketIndex !== -1) {
      const newState = [...state];
      newState.splice(ticketIndex, 1);
      return newState;
    }
  }
  if (action.type === 'RESET') {
    return [];
  }
  return state;
}

interface TicketsListProps {
  status: TicketStatus;
  searchParam?: string;
  showAll?: boolean;
  selectedQueueIds: number[];
  isGroups?: boolean;
  columns?: 1 | 2 | 3;
  readOnly?: boolean;
}

const TicketsList: FunctionComponent<TicketsListProps> = ({
  status,
  searchParam,
  showAll,
  selectedQueueIds,
  isGroups,
  columns,
  readOnly,
}) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user, userQueues, userWhatsapps } = useContext(AuthContext);
  const { showPendingTicketsWithoutQueue, showClosedTicketsWithoutQueue } = useContext(SettingsContext);

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, isGroups, selectedQueueIds]);

  const { tickets, hasMore, loading, backgroundRefresh } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    isGroups,
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    dispatch({
      type: 'SET_TICKETS',
      payload: tickets,
    });
  }, [tickets]);

  useEffect(() => {
    const socket = createSocketIo();
    const userQueuesIds = userQueues.map(q => q.id);
    const userWhatsappsIds = userWhatsapps.map(w => w.id);
    const shouldUpdateTicket = (ticket: Ticket) => {
      if (status) {
        const showPWithoutQueue = user.canSeeAllTickets() || showPendingTicketsWithoutQueue === 'enabled';
        const showCWithoutQueue = user.canSeeAllTickets() || showClosedTicketsWithoutQueue === 'enabled';
        switch (status) {
          // pendentes (criados pelo bot ou recebidos por transferencia indireta)
          case 'pending':
            return (
              // sem usuario, nas filas filtradas ou sem fila
              !ticket?.user?.id &&
              (showPWithoutQueue
                ? !ticket?.queue?.id || selectedQueueIds.includes(ticket.queue.id)
                : selectedQueueIds.includes(ticket?.queue?.id || 0)) &&
              ticket?.status === 'pending' &&
              ticket?.isGroup === false
            );
          // abertos (para o usuario que: aceitou, recebeu por transferencia direta, chamou o contato direto ou grupo)
          case 'open':
            if (isGroups === true) {
              // apenas grupos
              return (
                // grupos das conexoes do usuario
                !ticket?.user?.id &&
                !ticket?.queue?.id &&
                ticket?.status === 'open' &&
                ticket?.isGroup === true &&
                userWhatsappsIds.includes(ticket?.whatsapp?.id || 0)
              );
            }
            if (isGroups === false) {
              // sem grupos
              return (
                // do usuario, nas filas filtradas ou sem fila
                (ticket?.user?.id === user.id &&
                  (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
                  ticket?.status === 'open' &&
                  ticket?.isGroup === false) || // ou
                // do usuario, nas filas que o usuario nao participa (recebeu por transferencia direta)
                (ticket?.user?.id === user.id &&
                  !userQueuesIds.includes(ticket?.queue?.id) &&
                  ticket?.status === 'open' &&
                  ticket?.isGroup === false)
              );
            }
            // incluindo grupos
            return (
              // do usuario, nas filas filtradas ou sem fila
              (ticket?.user?.id === user.id &&
                (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
                ticket?.status === 'open' &&
                ticket?.isGroup === false) || // ou
              // do usuario, nas filas que o usuario nao participa (recebeu por transferencia direta)
              (ticket?.user?.id === user.id &&
                !userQueuesIds.includes(ticket?.queue?.id) &&
                ticket?.status === 'open' &&
                ticket?.isGroup === false) ||
              // grupos das conexoes do usuario
              (!ticket?.user?.id &&
                !ticket?.queue?.id &&
                ticket?.status === 'open' &&
                ticket?.isGroup === true &&
                userWhatsappsIds.includes(ticket?.whatsapp?.id || 0))
            );
          // finalizados (resolvidos pelo usuario que: aceitou, recebeu por transferencia direta ou chamou o contato)
          default:
            return (
              // de qualquer usuario, nas filas filtradas ou sem fila
              ((showCWithoutQueue
                ? !ticket?.queue?.id || selectedQueueIds.includes(ticket.queue.id)
                : selectedQueueIds.includes(ticket?.queue?.id || 0)) &&
                ticket?.status === 'closed' &&
                ticket?.isGroup === false) || // ou
              // do usuario, nas filas que o usuario nao participa (recebeu por transferencia direta)
              (ticket?.user?.id === user.id &&
                !userQueuesIds.includes(ticket?.queue?.id) &&
                ticket?.status === 'closed' &&
                ticket?.isGroup === false)
            );
        }
      }
      if (isGroups === true) {
        // todos grupos
        return (
          // grupos das conexoes do usuario
          !ticket?.user?.id &&
          !ticket?.queue?.id &&
          ticket?.status === 'open' &&
          ticket?.isGroup === true &&
          userWhatsappsIds.includes(ticket?.whatsapp?.id || 0)
        );
      }
      if (isGroups === false) {
        // todos sem grupos
        return (
          // sem usuario, nas filas filtradas ou sem fila, pendentes
          (!ticket?.user?.id &&
            (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
            ticket?.status === 'pending' &&
            ticket?.isGroup === false) || // ou
          // do usuario, nas filas filtradas ou sem fila, abertos
          (ticket?.user?.id === user.id &&
            (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
            ticket?.status === 'open' &&
            ticket?.isGroup === false) || // ou
          // de qualquer usuario, nas filas filtradas ou sem fila, resolvidos
          ((!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
            ticket?.status === 'closed' &&
            ticket?.isGroup === false) || // ou
          // do usuario, nas filas que o usuario nao participa (recebeu por transferencia direta), abertos ou resolvidos
          (ticket?.user?.id === user.id &&
            !userQueuesIds.includes(ticket?.queue?.id) &&
            ['open', 'closed'].includes(ticket?.status) &&
            ticket?.isGroup === false)
        );
      }
      // todos incluindo grupos
      return (
        // sem usuario, nas filas filtradas ou sem fila, pendentes
        (!ticket?.user?.id &&
          (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
          ticket?.status === 'pending' &&
          ticket?.isGroup === false) || // ou
        // do usuario, nas filas filtradas ou sem fila, abertos
        (ticket?.user?.id === user.id &&
          (!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
          ticket?.status === 'open' &&
          ticket?.isGroup === false) || // ou
        // de qualquer usuario, nas filas filtradas ou sem fila, resolvidos
        ((!ticket?.queue?.id || selectedQueueIds.includes(ticket?.queue?.id)) &&
          ticket?.status === 'closed' &&
          ticket?.isGroup === false) || // ou
        // do usuario, nas filas que o usuario nao participa (recebeu por transferencia direta), abertos ou resolvidos
        (ticket?.user?.id === user.id &&
          !userQueuesIds.includes(ticket?.queue?.id) &&
          ['open', 'closed'].includes(ticket?.status) &&
          ticket?.isGroup === false) || // ou
        // grupos das conexoes do usuario
        (!ticket?.user?.id &&
          !ticket?.queue?.id &&
          ticket?.status === 'open' &&
          ticket?.isGroup === true &&
          userWhatsappsIds.includes(ticket?.whatsapp?.id || 0))
      );
    };
    socket.on('connect', () => {
      if (status) {
        socket.emit('joinTickets', status);
      } else {
        socket.emit('joinNotification');
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    socket.on('ticket', (_data: any) => {
      // if (data.action === 'updateUnread') {
      //   dispatch({
      //     type: 'RESET_UNREAD',
      //     payload: data.ticketId,
      //   });
      // }
      // if (data.action === 'update') {
      //   if (shouldUpdateTicket(data.ticket)) {
      //     dispatch({
      //       type: 'UPDATE_TICKET',
      //       payload: data.ticket,
      //     });
      //   } else {
      //     dispatch({ type: 'DELETE_TICKET', payload: data.ticket.id });
      //   }
      // }
      // if (data.action === 'delete') {
      //   dispatch({ type: 'DELETE_TICKET', payload: data.ticketId });
      // }
      backgroundRefresh();
    });
    socket.on('appMessage', (data: any) => {
      if (data.action === 'create' && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: 'UPDATE_TICKET_UNREAD_MESSAGES',
          payload: data.ticket,
        });
      }
    });
    socket.on('contact', (data: any) => {
      if (data.action === 'update') {
        dispatch({
          type: 'UPDATE_TICKET_CONTACT',
          payload: data.contact,
        });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [
    status,
    showAll,
    user,
    userQueues,
    userWhatsapps,
    selectedQueueIds,
    isGroups,
    showPendingTicketsWithoutQueue,
    showClosedTicketsWithoutQueue,
    backgroundRefresh,
  ]);

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = (e: UIEvent) => {
    if (!hasMore || loading) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <div className={classes.ticketsListWrapper}>
      <Paper square elevation={0} className={classes.ticketsList} onScroll={handleScroll}>
        <List style={{ paddingTop: 0 }}>
          {status === 'open' && (
            <ListSubheader className={classes.ticketsListHeader}>
              <div>
                {i18n.t('ticketsList.assignedHeader')}
                <span className={classes.ticketsCount}>{ticketsList.length}</span>
              </div>
            </ListSubheader>
          )}
          {status === 'pending' && (
            <ListSubheader className={classes.ticketsListHeader}>
              <div>
                {i18n.t('ticketsList.pendingHeader')}
                <span className={classes.ticketsCount}>{ticketsList.length}</span>
              </div>
            </ListSubheader>
          )}
          {!loading &&
            (ticketsList.length === 0 ? (
              <div className={classes.noTicketsDiv}>
                <span className={classes.noTicketsTitle}>{i18n.t('ticketsList.noTicketsTitle')}</span>
                <p className={classes.noTicketsText}>{i18n.t('ticketsList.noTicketsMessage')}</p>
              </div>
            ) : (
              <Grid container>
                {ticketsList.map(ticket => (
                  // eslint-disable-next-line no-nested-ternary
                  <Grid item xs={columns === 3 ? 4 : columns === 2 ? 6 : 12} key={ticket.id}>
                    <TicketListItem ticket={ticket} readOnly={readOnly} />
                  </Grid>
                ))}
              </Grid>
            ))}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </div>
  );
};

TicketsList.defaultProps = {
  searchParam: '',
  showAll: false,
  isGroups: false,
  columns: 1,
  readOnly: false,
};

export default TicketsList;
