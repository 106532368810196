import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { defaultSettings, SettingsContext } from '../../context/Settings/SettingsContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n.js';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  settingOption: {
    marginLeft: 'auto',
  },
}));

export default function Settings() {
  const classes = useStyles();
  const storedSettings = useContext(SettingsContext);
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    setSettings(storedSettings);
  }, [storedSettings, setSettings]);

  async function handleChangeSetting(e) {
    const { name, value } = e.target;
    try {
      await api.put(`/settings/${name}`, {
        value: value,
      });
      toast.success(i18n.t('settings.success'));
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Typography variant="h6" color="textPrimary" style={{ paddingTop: 5 }}>
          {i18n.t('settings.title')}
        </Typography>
        <Paper className={classes.paper}>
          <Typography variant="body1">{i18n.t('settings.settings.userCreation.name')}</Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="userCreation-setting"
            name="userCreation"
            value={settings.userCreation}
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">{i18n.t('settings.settings.userCreation.options.enabled')}</option>
            <option value="disabled">{i18n.t('settings.settings.userCreation.options.disabled')}</option>
          </Select>
        </Paper>
      </Container>
    </div>
  );
}
