import React, { ReactElement, useContext, useState, useCallback, useRef } from 'react';

import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import NewTicketModal from '../NewTicketModal';
import TicketsList from '../TicketsList';

import { i18n } from '../../translate/i18n';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from '../Can';
import TicketsQueueSelect from '../TicketsQueueSelect';

import { Queue } from '../../services/types';
import useStyles from './styles';

export default function TicketsManager(): ReactElement {
  const classes = useStyles();
  const [searchParam, setSearchParam] = useState('');
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q: Queue) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const handleSearch = useCallback(e => {
    const searchedTerm = e.target.value.toLowerCase();
    setSearchParam(searchedTerm);
  }, []);

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal modalOpen={newTicketModalOpen} onClose={() => setNewTicketModalOpen(false)} />
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <Typography variant="h6" color="inherit" style={{ paddingTop: 5 }}>
          {i18n.t('tickets.tabs.open.title')}
        </Typography>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.serachInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t('tickets.search.placeholder')}
            type="search"
            onChange={handleSearch}
          />
        </div>

        <Can
          role={user.profile}
          perform="tickets-manager:showall"
          yes={() => (
            <FormControlLabel
              style={{ marginRight: 10 }}
              label={i18n.t('tickets.buttons.showAll')}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showAllTickets}
                  onChange={() => setShowAllTickets(prevState => !prevState)}
                  name="showAllTickets"
                  color="primary"
                />
              }
            />
          )}
        />
        <TicketsQueueSelect
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values: any) => setSelectedQueueIds(values)}
        />
      </Paper>
      <TicketsList
        status="open"
        showAll={showAllTickets}
        selectedQueueIds={selectedQueueIds}
        searchParam={searchParam}
        isGroups={false}
      />
    </Paper>
  );
}
