import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { IsMobileProvider } from '../hooks/useIsMobile';
import { AuthProvider } from '../context/Auth/AuthContext';
import { ManagerMainMenuProvider } from '../context/ManagerMainMenuDrawer';
import { ManagerDrawerQueueProvider } from '../context/ManagerDrawerQueue';
import { SettingsProvider } from '../context/Settings/SettingsContext';
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext';

import LoggedInLayout from '../layout';

import ConnectionsPage from '../pages/Connections';
import ContactsPage from '../pages/Contacts';
import DashboardPage from '../pages/Dashboard';
import LoginPage from '../pages/Login';
import QueuesPage from '../pages/Queues';
import SettingsPage from '../pages/Settings';
import SignupPage from '../pages/Signup';
import TicketsPage from '../pages/Tickets';
import TicketsPendingPage from '../pages/TicketsPending';
import TicketsResolvedPage from '../pages/TicketsResolved';
import TicketsGroupPage from '../pages/TicketsGroup';
import UsersPage from '../pages/Users';
import UserContactsPage from '../pages/UserContacts';

import Route from './Route';

export default function Routes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/signup" component={SignupPage} />
          <WhatsAppsProvider>
            <SettingsProvider>
              <IsMobileProvider>
                <ManagerMainMenuProvider>
                  <ManagerDrawerQueueProvider>
                    <Route
                      isPrivate
                      render={() => {
                        return (
                          <Switch>
                            <Route exact path="/pending" component={TicketsPendingPage} isPrivate />
                            <LoggedInLayout>
                              <Route exact path="/" component={DashboardPage} isPrivate />
                              <Route exact path="/tickets/:ticketId?" component={TicketsPage} isPrivate />
                              <Route exact path="/resolved/:ticketId?" component={TicketsResolvedPage} isPrivate />
                              <Route exact path="/groups/:ticketId?" component={TicketsGroupPage} isPrivate />
                              <Route exact path="/connections" component={ConnectionsPage} isPrivate />
                              <Route exact path="/contacts" component={ContactsPage} isPrivate />
                              <Route exact path="/users" component={UsersPage} isPrivate />
                              <Route exact path="/users/:userId/contacts" component={UserContactsPage} isPrivate />
                              <Route exact path="/Settings" component={SettingsPage} isPrivate />
                              <Route exact path="/Queues" component={QueuesPage} isPrivate />
                            </LoggedInLayout>
                          </Switch>
                        );
                      }}
                    />
                  </ManagerDrawerQueueProvider>
                </ManagerMainMenuProvider>
              </IsMobileProvider>
            </SettingsProvider>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
}
