interface RulesProps {
  user: {
    static: string[];
    dynamic?: string[];
  };
  man: {
    static: string[];
    dynamic?: string[];
  };
  admin: {
    static: string[];
    dynamic?: string[];
  };
}

const rules: RulesProps = {
  user: {
    static: ['contacts-page:deleteContact'],
  },
  man: {
    static: ['tickets-manager:showall', 'contacts-page:deleteContact'],
  },
  admin: {
    static: [
      'drawer-admin-items:view',
      'tickets-manager:showall',
      'user-modal:editProfile',
      'user-modal:editQueues',
      'ticket-options:deleteTicket',
      'contacts-page:deleteContact',
    ],
  },
};

export default rules;
