import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import useIsMounted from '../../hooks/useIsMounted';
import api from '../../services/api';
import { isQueue, isUser, WriteTicket } from '../../services/types';
import { i18n } from '../../translate/i18n';
import UserField from '../UserField';
import WhatsappsQueuesField from '../WhatsappsQueuesField';
import WhatsappsAllQueuesField from '../WhatsappsAllQueuesField';
import ButtonCancel from './ButtonCancel';
import ButtonOk from './ButtonOk';
import { useStyles } from './styles';
import { FormValues, Props, State } from './types';

const initialValues: FormValues = {
  queue: '',
  queueOther: '',
  user: '',
};

export default function TicketTransferModal({ modalOpen, onClose, ticket }: Props): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [state, setState] = useState<State>({
    option: 'queue',
  });

  const validation = useMemo(() => {
    const opt = state.option;
    return Yup.object().shape({
      queue: opt === 'queue' ? Yup.object().required('Obrigatório') : Yup.mixed().nullable(),
      user: opt === 'user' ? Yup.object().required('Obrigatório') : Yup.mixed().nullable(),
    });
  }, [state.option]);

  const handleSaveTicket = useCallback(
    (values: FormValues, actions: FormikHelpers<FormValues>) => {
      if (ticket) {
        let updated: Partial<WriteTicket> | undefined;
        if (state.option === 'queue' && isQueue(values.queue)) {
          updated = {
            status: 'pending',
            userId: null,
            queueId: values.queue.id,
          };
        } else if (state.option === 'other' && isQueue(values.queueOther)) {
          updated = {
            status: 'pending',
            userId: null,
            queueId: values.queueOther.id,
          };
        } else if (state.option === 'user' && isUser(values.user)) {
          updated = {
            status: 'open',
            userId: values.user.id,
          };
        }
        if (updated) {
          api
            .put(`/tickets/${ticket.id}`, updated)
            .then(() => history.replace(`/tickets`))
            .catch(err => toastError(err))
            .finally(() => {
              if (isMounted.current) {
                actions.setSubmitting(false);
              }
            });
        }
      }
    },
    [ticket, history, isMounted, state.option]
  );

  const handleChangeOption = useCallback(
    (e: ChangeEvent<unknown>, index: number) => {
      let tab: 'queue' | 'user' | 'other' = 'queue';
      if (index === 1) {
        tab = 'other';
      } else if (index === 2) {
        tab = 'user';
      } else {
        tab = 'queue';
      }
      setState(prevState => ({ ...prevState, option: tab }));
    },
    [setState]
  );

  const mapOptionValue = useCallback((option: State['option']) => {
    if (option === 'queue') {
      return 0;
    }
    if (option === 'other') {
      return 1;
    }
    return 2;
  }, []);

  return (
    <Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="xs" scroll="paper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={handleSaveTicket}
      >
        <Form>
          <DialogTitle>{i18n.t('transferTicketModal.title')}</DialogTitle>
          <DialogContent dividers style={{ padding: 0 }}>
            <div className={classes.tabsRoot}>
              <Tabs
                value={mapOptionValue(state.option)}
                onChange={handleChangeOption}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={i18n.t('transferTicketModal.option.queue')} />
                <Tab label={i18n.t('transferTicketModal.option.other')} />
                <Tab label={i18n.t('transferTicketModal.option.user')} />
              </Tabs>
            </div>
            <div className={classes.panel}>
              {state.option === 'queue' ? (
                <WhatsappsQueuesField name="queue" label="transferTicketModal.form.queue" autoFocus />
              ) : (
                <>
                  {state.option === 'other' ? (
                    <WhatsappsAllQueuesField name="queueOther" label="transferTicketModal.form.queue" autoFocus />
                  ) : (
                    <>{state.option === 'user' && <UserField name="user" autoFocus />}</>
                  )}
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonCancel onClick={onClose} />
            <ButtonOk />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
