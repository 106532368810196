import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

export const useStyles = makeStyles(theme => ({
    root       : {
        display : "flex",
        flexWrap: "wrap",
    },
    textField  : {
        marginRight: theme.spacing(1),
        flex       : 1,
    },
    formControl: {
        margin  : theme.spacing(1),
        minWidth: 120,
    },
    extraAttr  : {
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center",
    },
}));

export const useQueueFieldStyles = makeStyles(theme => ({
    chips: {
        display : "flex",
        flexWrap: "wrap",
    },
    chip : {
        margin: 2,
    },
}));

export const useButtonOkStyles = makeStyles(theme => ({
    btnWrapper    : {
        position: "relative",
    },
    buttonProgress: {
        color     : green[500],
        position  : "absolute",
        top       : "50%",
        left      : "50%",
        marginTop : -12,
        marginLeft: -12,
    },
}));
