import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

import TicketsResolvedManager from '../../../../components/TicketsResolvedManager';

import useStyles from '../../styles';

export default function Chat(): ReactElement {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.contactsWrapper}>
        <TicketsResolvedManager />
      </Grid>
    </Grid>
  );
}
